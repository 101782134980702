import React, { useState } from "react";
import { api } from "helper/constants";
import { useStore } from "store";

const DiscordMissionPopup = () => {
  const [username, setUsername] = useState("");
  const [showDiscordLink, setShowDiscordLink] = useState(false);
  const { profileDetails } = useStore();
  const saveDiscordUsername = async () => {
    const response = await api.post("/updateUser", {
      userWallet: profileDetails.userWallet,
      discord: username,
    });
    if (response.status === 200) {
      setShowDiscordLink(true);
      window.opener.postMessage(true);
    }
  };
  const inputChangeHandler = (e: any) => {
    setUsername(e.target.value);
  };
  const submitHandler = (e: any) => {
    e.preventDefault();
    saveDiscordUsername();
  };

  const joinLinkClickHandler = (_e: any) => {
    window.close();
  };

  return (
    <section className="flex flex-col gap-5 items-center justify-center text-white w-screen h-screen">
      <form
        className="flex flex-col items-center w-[75%] gap-2"
        onSubmit={submitHandler}
      >
        <p>Please enter your Discord username to continue with the Mission.</p>
        <input
          className="bg-[#00000000] p-2 border-b-2 border-[#FFFFFF55] outline-none"
          type="text"
          id="discord_username"
          onChange={inputChangeHandler}
          value={username}
          placeholder="Discord Username"
          autoComplete="off"
        />
        <button
          className="w-[13rem] h-[2.875rem] rounded-[1rem] blue_green_gradient_5 bg-gradient-to-r from-[#4f9bff1a] to-[#67ffd11a] flex justify-center items-center gradient-border mt-3"
          type="submit"
        >
          <span className="text-[0.875rem] text-[#67FFD1] font-[400] font-manrope">
            Submit
          </span>
        </button>
      </form>
      {showDiscordLink && (
        <div className="flex flex-col items-center w-75">
          <p>
            Thanks for authenticating! Please join our Discord server to
            complete the mission and earn your XP!
          </p>
          <a
            onClick={joinLinkClickHandler}
            className="text-[0.875rem] text-[#67FFD1] font-[400] font-manrope"
            href="https://discord.com/invite/tbuyRCkdrx"
            target="_blank"
            rel="noreferrer"
          >
            Join KanaLabs Gaming Discord Server
          </a>
        </div>
      )}
    </section>
  );
};

export default DiscordMissionPopup;
