import React, { useCallback, useEffect, useState } from "react";
import ProfileImg from "../assets/icons/profile_img.svg";
import Twitter from "../assets/icons/twitter_x_logo.svg";
import Telegram from "../assets/icons/telegram_logo.svg";
import Instagram from "../assets/icons/insta_logo.svg";
import { useTranslation } from "react-i18next";
import Sidebar from "../components/Sidebar";
import user2 from "../assets/icons/user2.svg";
import stack3 from "../assets/icons/stack3.svg";
import speaker from "../assets/icons/speaker.svg";
import linkshare from "../assets/icons/linkshare.svg";
import LeaderBoard from "./GameDashboard/LeaderBoard";
import Missions from "./GameDashboard/Missions";
import ShareSocialLink from "components/ShareSocialLink";
import CopyToClipboard from "react-copy-to-clipboard";
import { api } from "helper/constants";
import NavbarForPublicProfile from "components/NavbarForPublicProfile";
import { useStore } from "store";
import {
  getNumberOfReferrals,
  handleGetUserDetails,
} from "helper/reusableMethods";
import ComingSoon from "./ComingSoon";
import Toaster from "components/Toaster";
import NotFound from "./NotFound";
import membershipbadge from "../assets/icons/Membership Badge.svg";
import MembershipBadgenew from "../assets/icons/Membership Badge-New.svg";
import Level1 from "../assets/icons/Level-1badge.svg";
import Level2 from "../assets/icons/Level-2badge.svg";
import Level3 from "../assets/icons/Level-3badge.svg";
import Level4 from "../assets/icons/Level-4badge.svg";
import Level5 from "../assets/icons/Level-5badge.svg";

const badgeConstant = [
  {
    level: 0,
    levelIcon: MembershipBadgenew,
    levelName: "MemberShip",
    claimed: false,
  },
  {
    level: 1,
    levelIcon: Level1,
    levelName: "Newcomer Badge",
    claimed: false,
  },
  {
    level: 2,
    levelIcon: Level2,
    levelName: "Challenger Badge",
    claimed: false,
  },
  {
    level: 3,
    levelIcon: Level3,
    levelName: "Elite Champion Badge",
    claimed: false,
  },
  {
    level: 4,
    levelIcon: Level4,
    levelName: "Master Strategist Badge",
    claimed: false,
  },
  {
    level: 5,
    levelIcon: Level5,
    levelName: "Legend of the Ecosystem Badge",
    claimed: false,
  },
];
const PrivateProfile = () => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { profileDetails, updateProfileDetails, claimedBadges } = useStore();
  const [shortendUrl, setShortendUrl] = useState<string>("");
  const [numberOfReferrals, setNumberOfReferrals] = useState<any>();
  const [isClickedSideMenu, setIsClickedSideMenu] = useState<boolean>(false);
  const [isProfileExists, setIsProfileExists] = useState<boolean>(false);
  const [referrals, setReferrals] = useState(0);
  const [localDate, setLocalDate] =useState<any>();

  const [badgeDetails, setBadgeDetails] = useState(badgeConstant);


  useEffect(()=>{
    if(profileDetails){
      const date = new Date(profileDetails.signupTimestamp);
      setLocalDate(date.toLocaleString())
    }
  },[profileDetails])
  useEffect(() => {
    const claimedBadgesLength = claimedBadges && claimedBadges.length;
    const updatedBadges = badgeDetails.slice(0, claimedBadgesLength);
    setBadgeDetails(updatedBadges);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [claimedBadges]);

  const shortenerUrl = useCallback(async () => {
    const link = profileDetails?.referralLink;
    setShortendUrl(link);
    const addReferralLinkResponse = await api.post(`/addreferrallink`, {
      referralId: profileDetails?.userWallet,
      userWallet: profileDetails?.userWallet,
      referralLink: link,
    });
    if (addReferralLinkResponse.status === 200) {
      const { error, userDetails } = await handleGetUserDetails(
        profileDetails?.userWallet
      );
      if (!error) {
        updateProfileDetails(userDetails);
        handleGetNumberOfReferrals(userDetails);
        userReferrals(userDetails);
      }
    }
  }, []);

  const handleGetNumberOfReferrals = useCallback(async (userDetails: any) => {
    if (profileDetails?.userWallet) {
      const { numberOfReferral } = await getNumberOfReferrals(
        userDetails?.userWallet
      );
      setNumberOfReferrals(numberOfReferral);
    }
  }, []);
  useEffect(() => {
    shortenerUrl();
  }, []);

  const userReferrals = useCallback(async (userDetails: any) => {
    const referrals = await getNumberOfReferrals(userDetails.userWallet);
    if (referrals.error) return;

    setReferrals(referrals.numberOfReferral);
  }, []);

  const onCopy = () => {
    setErrorMessage("Link Copied!");
  };
  const handleActiveTab = (tab: string) => {
    if (tab === "home") {
      setIsClickedSideMenu(false);
    } else {
      setIsClickedSideMenu(true);
    }
  };
  const handleClose = () => {
    setIsClickedSideMenu(false);
  };
  const handleCloseToaster = () => {
    setErrorMessage("");
  };
  // useEffect(() => {
  //   if (errorMessage !== "") {
  //     setTimeout(() => setErrorMessage(""), 2000);
  //   }
  // }, [errorMessage]);


  return (
    <div className="bg-[#111213]">
      <NavbarForPublicProfile profileDetails={profileDetails} />
      {isClickedSideMenu ? (
        <ComingSoon handleClose={handleClose} />
      ) : profileDetails !== undefined ? (
        <>
          <div className="flex flex-row justify-start items-start">
            <Sidebar handleActiveTab={handleActiveTab} />
            <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[15rem] md:ml-0 sm:ml-0 xd:ml-0 container-fluid z-[1]">
              <div className=" xxl:m-[1.5rem] xl:m-[1.5rem] lg:m-[1.5rem] md:m-[1rem] sm:m-[1rem] xd:m-[1rem] !mt-[8rem] mb-[2rem]">
                <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  justify-start items-start gap-[16px]">
                  <div className="gap-[1rem] rounded-[1rem] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[3.375rem] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] xxl:p-[24px] xl:p-[24px] lg:p-[24px] md:p-[24px] sm:p-[12px] xd:p-[12px] shadow-custom xxl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full h-full ">
                    <div className="flex flex-row justify-between items-start">
                      <div className="flex flex-row justify-start items-start gap-[1.438rem]">
                        <div>
                          <img
                            className="xxl:w-auto xxl:h-auto xl:w-auto xl:h-auto lg:h-auto lg:w-auto md:h-auto md:w-16 sm:h-16 xd:w-16 xd:h-16"
                            src={ProfileImg}
                            alt="ProfileImg"
                          />
                        </div>
                        <div>
                          <div className="text-[white] text-[18px] font-manrope font-[500] pt-1">
                            {profileDetails.userName}
                          </div>
                          {/* <div className="text-[white] text-[16px] font-manrope font-[400]">
                            userid
                          </div> */}
                          <div className="flex-col xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden">
                            <div className="text-[#697586] text-[1rem] font-manrope font-[400] py-[1rem]">
                              {t("knows")}
                              <span className="px-2 text-[1rem] font-manrope font-[400] text-[white]">
                                {profileDetails.language}
                              </span>
                            </div>
                            <div className="text-[#697586] text-[1rem] font-manrope font-[400] py-[1rem]">
                              {t("Active Since")}
                              <span className="px-2 text-[1rem] font-manrope font-[400] text-[white]">
                                {localDate}
                              </span>
                            </div>
                            {/* <div className="text-[#697586] text-[16px] font-manrope font-[400]">
                              {t("active_since")}{" "}
                              <span className="px-2 text-[16px] font-manrope font-[400] text-[white]">
                                1/1/2023
                              </span>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="rounded-[8px] border-[rgba(245,247,250,0.06)] border-[1px] p-[8px] shadow-custom backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250, 0.00)] to-[rgba(245,247,250,0.00)] cursor-pointer">
                        <img src={Pencil} alt="pencil" />
                      </div> */}
                    </div>
                    <div className="flex-col xxl:hidden xl:hidden lg:hidden md:flex sm:flex xd:flex">
                      <div className="text-[#697586] text-[1rem] font-manrope font-[400] py-[1rem]">
                        {t("knows")}
                        <span className="px-2 text-[1rem] font-manrope font-[400] text-[white]">
                          {profileDetails.language}
                        </span>
                      </div>
                    </div>
                    <div className="text-[#697586] text-[1rem] font-manrope font-[400] pt-3 pb-2">
                      {t("plays")}
                    </div>
                    <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-start items-start gap-[16px]">
                      {profileDetails !== undefined &&
                        profileDetails?.preferredGenres !== undefined &&
                        profileDetails?.preferredGenres.map(
                          (member: string) => {
                            return (
                              <div
                                key={member}
                                className="flex flex-row justify-start items-start gap-[16px]"
                              >
                                <div className="text-[#9AA4B2] text-[14px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939]">
                                  {member}
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                    {/* <div className="text-[#697586] text-[16px] font-manrope font-[400] pt-3 pb-2">
                      {t("socials")}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-[1rem]">
                      <div className="flex flex-col justify-start items-start gap-[1rem]">
                        <div className="text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[1rem] py-[0.5rem] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Instagram} alt="Instagram" />
                          {profileDetails.instagram}
                        </div>
                        <div className="text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[1rem] py-[0.5rem] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Twitter} alt="Twitter" />
                          {profileDetails.twitter}
                        </div>
                        <div className="xxl:flex xl:flex lg:flex md:flex sm:hidden xd:hidden text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Telegram} alt="Telegram" />
                          {profileDetails.telegram}
                        </div>
                      </div>
                      <div className="flex flex-row justify-start items-start gap-[16px]">
                        <div className="xxl:hidden xl:hidden lg:hidden md:hidden sm:flex xd:flex text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Telegram} alt="Telegram" />
                          {profileDetails.discord}
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="bg-no-repeat bg-left  bg-auto gap-[32pxpx] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] px-[24px] py-[32px] shadow-custom xxl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full h-full ">
                    <div className="flex flex-row justify-start items-center gap-[24px]">
                      <div>
                        <img src={Polygon} alt="Polygon" />
                      </div>
                      <div>
                        <div className="text-[16px] font-[manrope] text-[#697586] font-[400]">
                          {t("total_xp")}
                        </div>
                        <div className="text-[20px] font-[manrope] text-[#9AA4B2] font-[800]">
                          12345
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-[24px]">
                      <div>
                        <img src={Polygon} alt="Polygon" />
                      </div>
                      <div>
                        <div className="text-[16px] font-[manrope] text-[#697586] font-[400]">
                          {t("last_active")}
                        </div>
                        <div className="text-[20px] font-[manrope] text-[#9AA4B2] font-[800]">
                          4 days ago
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center gap-[24px]">
                      <div>
                        <img src={Polygon} alt="Polygon" />
                      </div>
                      <div>
                        <div className="text-[16px] font-[manrope] text-[#697586] font-[400]">
                          {t("time_spent")}
                        </div>
                        <div className="text-[20px] font-[manrope] text-[#9AA4B2] font-[800]">
                          23.5 Hours
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              
                {badgeDetails && badgeDetails.length > 0 && (
                  <>
                    <div className=" pt-4 pb-3">
                  <div className=" flex justify-between w-full h-full">
                    <div className=" text-[1.125rem] font-manrope font-[500] text-[#FFFFFF] cursor-pointer ">
                      {t("badges")}
                    </div>
                    {/* <div className=" text-[0.875rem] font-manrope font-[400] text-[#67FFD1] flex cursor-pointer">
                      {t("view_all")}
                      <img src={UpLeftArrow} alt="" className=" pl-2 w-6 h-6" />
                    </div> */}
                  </div>
                </div>
                  <div className="gap-[8px] xxl:p-[48px] xl:p-[48px] lg:p-[48px] md:p-[48px] sm:px-[24px]  sm:py-[32px] xd:px-[24px]  xd:py-[32px] rounded-[16px] flex flex-row justify-start items-center border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] p-[24px] shadow-custom w-full h-full ">
                    {badgeDetails.map((badgeLevel, index) => {
                      if (claimedBadges?.length === index) return 0;
                      return (
                        <div key={index} className="flex flex-col items-center">
                          <img
                            src={badgeLevel.levelIcon}
                            alt={`Level ${badgeLevel.level}`}
                          />
                          <div className="text-[#9AA4B2] text-[14px] font-manrope font-[400] pt-2">
                            {badgeLevel.levelName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  </>
                )}

                <div className="text-[18px] font-manrope font-[500] text-white mt-[28px] mb-[16px]">
                  {t("other_stats")}
                </div>
                <div className=" xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-start items-start   gap-[16px]">
                  <Missions />
                  <LeaderBoard />
                </div>
                <div className=" pt-4 pl-5">
                  <div className=" flex justify-between w-full h-full">
                    <div className=" text-[1.125rem] font-manrope font-[500] text-[#FFFFFF] cursor-pointer ">
                      {t("Referrals")}
                    </div>
                    {/* <div className=" text-[0.875rem] font-manrope font-[400] text-[#67FFD1] flex cursor-pointer">
                      {t("Referrals")}
                      <img src={UpLeftArrow} alt="" className=" pl-2 w-6 h-6" />
                    </div> */}
                  </div>
                </div>
                <div className=" pt-4  w-[100%]">
                  <div className=" gap-[16px] xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  ">
                    <div className=" xxl:w-1/2  xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full">
                      <div className="  rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] p-4">
                        <div className=" text-[1.25rem] font-manrope font-[400] text-[#697586]">
                          {t("you_have_sucessfully_completed")}
                        </div>
                        <div className="text-[36px] font-[manrope] font-[800] text-[white]">
                          {referrals}&nbsp; referrals
                        </div>
                      </div>
                      <div className="  rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] mt-3 p-4">
                        <div className=" text-[1.25rem] font-manrope font-[400] text-[#697586]">
                          {t("total_xp_earned")}
                        </div>
                        <div className=" text-[2.25rem] font-manrope font-[800] text-white">
                          {referrals * 20} XP
                        </div>
                      </div>
                    </div>
                    <div className="  xxl:w-1/2  xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full ">
                      <div className="  border-box bg-black rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] p-[16px] h-[17.8rem]">
                        <div className=" flex justify-between  mx-5">
                          <div className="bg-[#1d1e20] rounded-[0.5rem] w-[3rem] h-[3rem] flex justify-center items-center">
                            <img src={speaker} alt="speaker" />
                          </div>
                          <div className=" xxl:w-[8rem] xl:w-[8rem] lg:w-[8rem] md:w-[8rem] sm:w-[4rem] xd:w-[4rem] h-[0.1rem] bg-[#202939] rounded-full mt-4"></div>
                          <div className="bg-[#1d1e20] rounded-[0.5rem] w-[3rem] h-[3rem] flex justify-center items-center">
                            <img src={user2} alt="speaker" />
                          </div>
                          <div className=" xxl:w-[8rem] xl:w-[8rem] lg:w-[8rem] md:w-[8rem] sm:w-[4rem] xd:w-[4rem] h-[0.1rem] bg-[#202939] rounded-full mt-4"></div>
                          <div className="bg-[#1d1e20] rounded-[0.5rem] w-[3rem] h-[3rem] flex justify-center items-center">
                            <img src={stack3} alt="speaker" />
                          </div>
                        </div>
                        <div className=" flex justify-around  py-2  ">
                          <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem]">
                            {t("share_your_invite")}
                          </div>
                          <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem]  ">
                            {t("your_friend_joins")}
                          </div>
                          <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem]  ">
                            {t("you_get_xp")}
                          </div>
                        </div>
                        <div className=" xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-around items-center  gap-[16px] p-10">
                          <div className="  border-dashed border-[0.063rem] border-[#364152]   items-center h-[2.25rem] rounded-[0.5rem] flex justify-between p-2">
                            <div className=" flex items-center">
                              <img
                                src={linkshare}
                                alt="linkshare/"
                                className=" w-[1rem] h-[1rem]"
                              />
                              <div className="text-[0.875rem] text-[#9AA4B2] font-manrope font-[400] pl-2 truncate w-44">
                                {shortendUrl && shortendUrl}
                              </div>
                            </div>
                            <CopyToClipboard
                              text={shortendUrl}
                              onCopy={() => onCopy()}
                            >
                              <div className="text-[0.875rem] text-[#67FFD1] font-[800] font-manrope cursor-pointer">
                                &nbsp; {t("copylink")}
                              </div>
                            </CopyToClipboard>
                          </div>

                          <div className=" flex justify-center">
                            <ShareSocialLink />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage !== "" && (
            <Toaster
              message={errorMessage}
              handleCloseToaster={handleCloseToaster}
            />
          )}
        </>
      ) : (
        <>
          <NotFound />
        </>
      )}
    </div>
  );
};

export default PrivateProfile;
