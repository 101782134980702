import React, { useState } from "react";
import Close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import kanaframe from "../assets/icons/kana-frame.svg";
import { api } from "helper/constants";
import { useStore } from "store";
import Loader from "./Loader";

interface DiscordpopupInterface {
  handleCloseDiscordPopup(value: any): void;
}
const Discordpopup = (props: DiscordpopupInterface) => {
  const { handleCloseDiscordPopup } = props;
  const { profileDetails, address, updateProfileDetails } = useStore();
  const { t } = useTranslation();
  const [discordName, setDiscordName] = useState<any>();
  const [isUserNameUpdated, setIsUserNameUpdated] = useState<boolean>(false);
  const [userNotCompleted, setUserNotCompleted] = useState<boolean>(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

  const handleDiscordNameChange = (enteredValue: any) => {
    setDiscordName(enteredValue);
  };

  const handleSaveDiscordProfile = async () => {
    const response = await api.post("/updateUser", {
      userWallet: profileDetails.userWallet,
      discord: discordName,
    });
    if (response.status === 200) {
      setIsUserNameUpdated(true);
      setIsLoaderVisible(false);
    }
  };

  const handleClaimDiscordReward = async () => {
    setTimeout(async () => {
      const missions: any = await api
        .get("/getNotCompletedMissions", {
          params: { userWallet: profileDetails.userWallet },
        })
        .catch((err: any) => {});
      missions.data.userDetails.map((mission: any) => {
        if (mission.mission_id === "m_fol_tw_092023") {
          setUserNotCompleted(true);
          handleCloseDiscordPopup(false);
          setIsLoaderVisible(false);
        }
      });
      handleCloseDiscordPopup(true);
    }, 2000);
  };

  return (
    <div>
      <div className="flex flex-row justify-start items-start">
        <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[14rem] container-fluid z-[1]">
          <div className="fixed z-[2]  inset-0 h-full w-full backdrop-blur-[12px] bg-[rgba(0,0,0,0.20)]  flex flex-row   justify-center xxl:items-center xl:items-center lg:items-center md:items-center sm:items-end xd:items-end">
            <div className=" flex flex-col rounded-[16px] xxl:w-[35rem] xl:w-[35rem] lg:w-[35rem] md:w-[35rem] sm:w-[28rem] xd:w-[24rem] border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
              <div className=" bg-[#ffffff0f] rounded-t-[1rem] h-[4.5rem] w-full flex justify-between p-4">
                <div className=" text-[white] font-inter font-[700] texxt-[1.25rem]">
                  {t("follow_us_on_discord")}
                </div>
                <img
                  onClick={handleCloseDiscordPopup}
                  src={Close}
                  alt="Close"
                  className=" cursor-pointer"
                />
              </div>
              <div className=" flex justify-between p-3 border-b-2 border-[rgba(245,247,250,0.06)] ">
                <div className=" flex flex-col">
                  <span className="text-[#D2D2D2] font-inter font-[500]">
                    Enter Discord Name
                  </span>
                  <input
                    className="bg-[#00000000] pt-3 border-b-2 h-[3.5rem] border-[#FFFFFF55] outline-none text-white"
                    type="text"
                    min="0"
                    autoFocus={true}
                    id="discord_username"
                    onChange={(e) => handleDiscordNameChange(e.target.value)}
                    value={discordName}
                    placeholder="Discord Username"
                    autoComplete="off"
                  />
                </div>
                <button
                  onClick={() => handleSaveDiscordProfile()}
                  className="h-[51px] w-[168px] flex flex-row justify-center items-center cursor-pointer rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow"
                >
                  Verify Discord
                  {/* {t("discord_connect")} */}
                </button>
              </div>

              <div className=" flex justify-center items-center py-3">
                <div
                  className={` bg-[#0C0C0D] gap-[8px] w-[528px] h-[96px] rounded-[1rem] flex justify-between p-[2%_5%] ${
                    !isUserNameUpdated && "opacity-20"
                  }`}
                >
                  <div className=" flex">
                    <img src={kanaframe} alt="kana-frame" />
                    <div className=" text-white flex flex-col text-[1rem] font-inter font-[800] px-3 py-2.5">
                      Kanalabs
                      <span className=" text-[#A5A5A6] font-[400] font-manrope pt-1">
                        @kanalabs
                      </span>
                    </div>
                  </div>
                  <a
                    href="https://discord.gg/dWejDKVeDU"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className="h-[51px] w-[97px] cursor-not-allowed	 my-2 flex flex-row justify-center items-center rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow">
                      Join Discord 
                    </button>
                  </a>
                </div>
              </div>
              <div
                onClick={() => handleClaimDiscordReward()}
                className=" flex justify-center py-3"
              >
                <button className="h-[51px] w-[512px]  flex flex-row justify-center items-center cursor-pointer rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow">
                  {t("claim_reward")}
                </button>
              </div>
            </div>
          </div>
        </div>
        {isLoaderVisible && <Loader />}
      </div>
    </div>
  );
};

export default Discordpopup;
