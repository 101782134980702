import React from "react";
import Close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import kanaframe from "../assets/icons/kana-frame.svg";

const SteamPopup = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="flex flex-row justify-start items-start">
        <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[14rem] container-fluid z-[1]">
          <div className="fixed z-[2]  inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[12px]  flex flex-row   justify-center xxl:items-center xl:items-center lg:items-center md:items-center sm:items-end xd:items-end">
            <div className="h-[23.563rem] flex flex-col rounded-[16px] w-[35rem] border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
              <div className=" bg-[#ffffff0f] rounded-t-[1rem] h-[4.5rem] w-full flex justify-between p-4">
                <div className=" text-[white] font-inter font-[700] texxt-[1.25rem]">
                  {t("follow_us_on_steam")}
                </div>
                <img src={Close} alt="Close" className=" cursor-pointer" />
              </div>
              <div className=" flex justify-between p-3 border-b-2 border-[rgba(245,247,250,0.06)] ">
                <span className="text-[#D2D2D2] font-inter font-[500] pt-3">
                  {t("connect_discord")}
                </span>
                <button className="h-[51px] w-[168px] flex flex-row justify-center items-center cursor-pointer rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow">
                  {t("steam_connect")}
                </button>
              </div>
              <div className=" flex justify-center items-center py-3">
                <div className=" bg-[#0C0C0D] gap-[8px] w-[528px] h-[96px] rounded-[1rem] flex justify-between p-[2%_5%] opacity-20 ">
                  <div className=" flex">
                    <img src={kanaframe} alt="kana-frame" />
                    <div className=" text-white flex flex-col text-[1rem] font-inter font-[800] px-3 py-2.5">
                      Kanalabs
                      <span className=" text-[#A5A5A6] font-[400] font-manrope pt-1">
                        @kanalabs
                      </span>
                    </div>
                  </div>
                  <button className="h-[51px] w-[97px] cursor-not-allowed	 my-2 flex flex-row justify-center items-center rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow">
                    Follow
                  </button>
                </div>
              </div>
              <div className=" flex justify-center py-3">
                <button className="h-[51px] w-[512px]  flex flex-row justify-center items-center cursor-pointer rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow">
                  {t("claim_reward")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SteamPopup;
