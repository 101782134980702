import React from "react";
import { useState } from "react";
import Close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import comingsoon from "../assets/icons/comingsoon.svg";
import Downrightarrow from "../assets/icons/Downrightarrow.svg";
import Toaster from "../components/Toaster";

interface ComingSoonInterface {
  handleClose(): void;
}

const ComingSoon = (props: ComingSoonInterface) => {
  const { handleClose } = props;
  const { t } = useTranslation();
  const [email, updateEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleSubmit = async () => {
    if (email.length > 5) {
      const scriptURL =
        "https://script.google.com/macros/s/AKfycbxiArqcZowCjIPLSkxD0jARpw1LujPpJMfn2i5d2EZeIvnUvwxxfY5BTW04oleBgFv1/exec";
      await fetch(scriptURL, { method: "POST", body: email })
        .then((response) => {
          setErrorMessage(`Thank you, we have received your email! `);
        })
        .catch((error) => {
          setErrorMessage(
            `Oops! Error in receiving your email, Please try again `
          );
        });
      setTimeout(() => {
        handleClose();
      }, 1000);
      return;
    }
    setErrorMessage("Please Enter Email!");
  };

  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  return (
    <>
      <div className="flex flex-row justify-start items-start">
        <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[14rem] container-fluid z-[1]">
          <div className="fixed z-[2]  inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[54px]  flex flex-row   justify-center xxl:items-center xl:items-center lg:items-center md:items-center sm:items-end xd:items-end">
            <div className="h-[36.372rem]   p-[16px] flex flex-col rounded-[16px] w-[34.5rem] border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
              <div className="mb-[25px] xxl:flex xl:flex lg:flex md:flex sm:hidden xd:hidden flex-row justify-between p-[0%_1%] items-center text-[white] text-[24px] font-inter font-[700]">
                <div className="">{t("stay_up_to_date")}</div>
                <div onClick={handleClose} className="cursor-pointer">
                  <img src={Close} alt="Close" />
                </div>
              </div>
              <div className=" flex justify-center">
                <img
                  src={comingsoon}
                  alt="comingsoon"
                  className="xxl:w-[31.5rem] xl:w-[31.5rem] lg:w-[31.5rem] md:w-[31.5rem] xxl:h-[21.375rem] xl:h-[21.375rem] lg:h-[21.375rem] md:h-[21.375rem] sm:w-[22.875rem] xd:w-[22.875rem] sm:h-[21.375rem] xd:h-[21.375rem]"
                />
              </div>
              <div className=" text-white font-urbanist text-center xx:text-[1.25rem] xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1.25rem] sm:text-[1rem] xd:text-[1rem] xxl:pt-3 xl:pt-3 lg:pt-3 md:pt-3 sm:pt-0 xd:pt-0">
                {t("perks_enjoy")}
              </div>
              <div className=" text-white text-center font-urbanist xx:text-[1.25rem] xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1.25rem] sm:text-[1rem] xd:text-[1rem]">
                {t("new_layer")}
                <span className="comingsoon_container font-[400] xx:text-[1.25rem] xl:text-[1.25rem] lg:text-[1.25rem] md:text-[1.25rem] sm:text-[1rem] xd:text-[1rem]">
                  {t("gaming_journey")}
                </span>
              </div>
              <div
                id="div"
                className=" xxl:flex xl:flex  lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-between xxl:p-[2%_1%] xl:p-[2%_1%] lg:p-[2%_1%] md:p-[2%_1%] sm:p-[2%_5%] xd:p-[2%_5%]"
              >
                <input
                  placeholder="Email*"
                  required
                  name="email"
                  autoComplete="off"
                  type="email"
                  value={email}
                  onChange={(e) => updateEmail(e.target.value)}
                  className=" w-[21.5rem] h-[3rem] rounded-[0.25rem] pl-2 text-[#7D8595] bg-[#1D1E20] border-2 border-[#7D8595] outline-none"
                />
                <button
                  onClick={() => handleSubmit()}
                  className="xxl:w-[9.5rem] xl:w-[9.5rem] lg:w-[9.5rem] md:w-[9.5rem] sm:w-[21.5rem] xd:w-[21.5rem] h-[3rem] comingsoon_button comingsoon_button_gradient text-[0.875rem] font-manrope font-[400] flex justify-center items-center text-[#67FFD1] xxl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 sm:mt-2 xd:mt-2"
                >
                  {t("join_waitlist")}
                  <img
                    src={Downrightarrow}
                    alt="Downrightarrow"
                    className=" pl-2"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {errorMessage !== "" && (
          <Toaster
            message={errorMessage}
            handleCloseToaster={handleCloseToaster}
          />
        )}
      </div>
    </>
  );
};

export default ComingSoon;
