import React, { useState } from "react";
import Logo from '../assets/icons/kana_logo.svg';
import Mobile_menu from '../assets/icons/menu.svg';
import HeaderModal from "components/HeaderModal";
const NotFound = () => {
    const [isOpenHeader, setIsOpenHeader] = useState(false);
    const handleHeaderModal = () => {
        setIsOpenHeader(!isOpenHeader);
    }
    return (
        <div>
            <div className='!sticky w-full top-0 text-[white] h-[96px] !z-[2] flex flex-row justify-between items-center py-[30px] xxl:px-[48px] xl:px-[48px] lg:px-[48px] md:px-[48px] sm:px-[20px] xd:px-[20px] !bg-[rgba(29,30,32,0.1)] backdrop-blur-[50px] '>
                <div className='xxl:flex-[0.2] xl:flex-[0.2]  lg:flex-[0.2] md:flex-[0.2] sm:flex-[0.9] xd:flex-[0.9]'><a href='https://kanalabs.io/'><img src={Logo} alt='kana_logo' /></a>  </div>
                <div className='flex-[0.6] xxl:flex xl:flex lg:flex md:flex  sm:hidden xd:hidden flex-row justify-center items-center text-[white] text-[16px] font-manrope font-[600] gap-[34px]'>
                </div>
                <div className='flex-[0.2] flex flex-row justify-end items-end' onClick={()=>handleHeaderModal()}>
                    <img src={Mobile_menu} alt='Mobile_menu' className=' cursor-pointer' />
                </div>
            </div>
            {isOpenHeader &&
                <HeaderModal handleCloseHeader={handleHeaderModal} />
            }
            <div onClick={()=>handleHeaderModal()}>
                <div className="flex flex-col justify-center items-center h-full w-full font-manrope">
                    <div className="text-[#67FFD1] text-[4rem] font-[700] pt-24">404 - Sorry!</div>
                    <div className="text-[white] text-[24px] font-[500] pt-4 pb-2">The page you’re looking for cannot be found.</div>
                    <div><a href='https://kanalabs.io/' className="text-[20px] font-[500] text-[#4F9DFF] hover:text-[#4F9DFF]" >Go to Homepage</a></div>
                </div>
            </div>
        </div>
    )
}

export default NotFound
