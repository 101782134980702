import React, { useCallback, useEffect, useState } from "react";
import ProfileImg from "../assets/icons/profile_img.svg";
import Twitter from "../assets/icons/twitter_x_logo.svg";
import Discord from "../assets/icons/discord_logo.svg";
import Telegram from "../assets/icons/telegram_logo.svg";
import Instagram from "../assets/icons/insta_logo.svg";
import Polygon from "../assets/icons/Polygon.svg";
import { useTranslation } from "react-i18next";
import Sidebar from "../components/Sidebar";
import { useStore } from "store";
import { useParams } from "react-router-dom";
import NotFound from "./NotFound";
import NavbarForPublicProfile from "components/NavbarForPublicProfile";
import ComingSoon from "./ComingSoon";
import linkshare from "../assets/icons/linkshare.svg";
import membershipbadge from "../assets/icons/Membership Badge.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import Toaster from "../components/Toaster";
import Loader from "./Loader";
import ShareSocialLink from "components/ShareSocialLink";
import { api } from "helper/constants";
import { handleGetClaimFromDB } from "helper/reusableMethods";
import MembershipBadgenew from "../assets/icons/Membership Badge-New.svg";
import Level1 from "../assets/icons/Level-1badge.svg";
import Level2 from "../assets/icons/Level-2badge.svg";
import Level3 from "../assets/icons/Level-3badge.svg";
import Level4 from "../assets/icons/Level-4badge.svg";
import Level5 from "../assets/icons/Level-5badge.svg";

const badgeConstant = [
  {
    level: 0,
    levelIcon: MembershipBadgenew,
    levelName: "MemberShip",
    claimed: false,
  },
  {
    level: 1,
    levelIcon: Level1,
    levelName: "Newcomer Badge",
    claimed: false,
  },
  {
    level: 2,
    levelIcon: Level2,
    levelName: "Challenger Badge",
    claimed: false,
  },
  {
    level: 3,
    levelIcon: Level3,
    levelName: "Elite Champion Badge",
    claimed: false,
  },
  {
    level: 4,
    levelIcon: Level4,
    levelName: "Master Strategist Badge",
    claimed: false,
  },
  {
    level: 5,
    levelIcon: Level5,
    levelName: "Legend of the Ecosystem Badge",
    claimed: false,
  },
];

const PublicProfile = () => {
  const { t } = useTranslation();
  const { updateActiveTab } = useStore();
  const [profileDetails, setProfileDetails] = useState<any>();
  const [isClickedSideMenu, setIsClickedSideMenu] = useState<boolean>(false);
  const id = useParams();
  const [shortendUrl, setShortendUrl] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isProfileExists, setIsProfileExists] = useState<boolean>(false);
  const [referrals, setReferrals] = useState([]);
  const [completedMission, setCompletedMission] = useState([]);
  const [badgeDetails, setBadgeDetails] = useState(badgeConstant);

  const handleGetLeaderBoard = useCallback(async () => {
    const userResponse: any = await api
      .get(`/getUserByUsername?username=${id.fullName}`)
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(""));
    setIsProfileExists(true);
    setProfileDetails(userResponse.data.userDetails[0]);

    // if (res.length > 0) {
    //   setProfileDetails(res[0]);
    //   return;
    // }
  }, [id.fullName]);

  useEffect(() => {
    handleGetLeaderBoard();
  }, [handleGetLeaderBoard]);

  const handleActiveTab = (tab: string) => {
    if (tab === "home") {
      setIsClickedSideMenu(false);
    } else {
      setIsClickedSideMenu(true);
    }
  };

  const handleClose = () => {
    setIsClickedSideMenu(false);
  };

  const onCopy = () => {
    setErrorMessage("Link Copied!");
  };

  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => setErrorMessage(""), 2000);
    }
  }, [errorMessage]);

  const shortenerUrl = useCallback(async () => {
    const link = profileDetails?.referral_link;
    setShortendUrl(link);
    updateActiveTab("profile");
  }, [profileDetails?.userWallet, updateActiveTab]);

  const handleClaimedBadges = async () => {
    if (profileDetails && profileDetails.userWallet) {
      const { status } = await handleGetClaimFromDB(
        profileDetails?.userWallet
      );
    }
  };

  useEffect(() => {
    shortenerUrl();
    if (profileDetails && profileDetails.userWallet) userReferrals();
    userXp();
    handleClaimedBadges();
  }, [profileDetails, shortenerUrl]);

  useEffect(() => {
    const updatedBadges = badgeDetails.slice(0);
    setBadgeDetails(updatedBadges);
  }, [badgeDetails]);

  const userReferrals = useCallback(async () => {}, []);

  const userXp = useCallback(async () => {
    if (profileDetails && profileDetails.userWallet) {
      const result = await api.get(
        `/getCompletedMissions?userWallet=${profileDetails.userWallet}`
      );
      setCompletedMission(result.data.userDetails);
      const referrals = await api.get(
        `/getReferralRecords?referrer_wallet=${profileDetails.userWallet}`
      );
      setReferrals(referrals.data.userDetails);
    }
  }, [profileDetails]);

  return (
    <div>
      <NavbarForPublicProfile profileDetails={profileDetails} />
      {isClickedSideMenu ? (
        <ComingSoon handleClose={handleClose} />
      ) : !isProfileExists ? (
        <Loader />
      ) : profileDetails !== undefined ? (
        <>
          <div className="flex flex-row justify-start items-start">
            <Sidebar handleActiveTab={handleActiveTab} />
            <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[14rem] container-fluid z-[1] bg-[#111213]">
              <div className=" xxl:m-[24px] xl:m-[24px] lg:m-[24px] md:m-[24px] sm:m-[16px] xd:m-[16px] !mt-[6rem]">
                <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  justify-start items-start gap-[16px] pt-4">
                  <div className="gap-[16px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] xxl:p-[24px] xl:p-[24px] lg:p-[24px] md:p-[24px] sm:p-[12px] xd:p-[12px] shadow-custom xxl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full h-full ">
                    <div className="flex flex-row justify-start items-start gap-[23px]">
                      <div>
                        <img
                          className="xxl:w-auto xxl:h-auto xl:w-auto xl:h-auto lg:h-auto lg:w-auto md:h-auto md:w-16 sm:h-16 xd:w-16 xd:h-16"
                          src={ProfileImg}
                          alt="ProfileImg"
                        />
                      </div>
                      <div>
                        <div className="text-[white] text-[18px] font-manrope font-[500] pt-2">
                          {profileDetails.username}
                        </div>
                        <div className="flex-col xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden">
                          <div className="text-[#697586] text-[16px] font-manrope font-[400] py-[16px]">
                            {t("knows")}
                            <span className="px-2 text-[16px] font-manrope font-[400] text-[white]">
                              {profileDetails.language}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex-col xxl:hidden xl:hidden lg:hidden md:flex sm:flex xd:flex">
                      <div className="text-[#697586] text-[16px] font-manrope font-[400] py-[16px]">
                        {t("knows")}
                        <span className="px-2 text-[16px] font-manrope font-[400] text-[white]">
                          English, Tamil
                        </span>
                      </div>
                      <div className="text-[#697586] text-[16px] font-manrope font-[400]">
                        {t("active_since")}
                        <span className="px-2 text-[16px] font-manrope font-[400] text-[white]">
                          English, Tamil
                        </span>
                      </div>
                    </div>
                    <div className="text-[#697586] text-[16px] font-manrope font-[400] pt-3 pb-2">
                      {t("plays")}
                    </div>
                    <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-col xd:flex-col justify-start items-start gap-[16px]">
                      {JSON.parse(profileDetails.preferred_genres[0]).map(
                        (member: string) => {
                          return (
                            <div
                              key={member}
                              className="flex flex-row justify-start items-start gap-[16px]"
                            >
                              <div className="text-[#9AA4B2] text-[14px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939]">
                                {member}
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                    <div className="text-[#697586] text-[16px] font-manrope font-[400] pt-3 pb-2">
                      {t("socials")}
                    </div>
                    <div className="flex flex-col justify-start items-start gap-[16px]">
                      <div className=" flex flex-col justify-start items-start gap-[16px]">
                        <div className="text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Instagram} alt="Instagram" />
                          {profileDetails.instagram_profile}
                        </div>
                        <div className="text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Twitter} alt="Twitter" />
                          {profileDetails.twitter}
                        </div>
                      </div>
                      <div className="flex flex-row justify-start items-start gap-[16px]">
                        <div className="xxl:hidden xl:hidden lg:hidden md:hidden sm:flex xd:flex text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Telegram} alt="Telegram" />
                          {profileDetails.telegram_contact}
                        </div>
                        <div className="text-[white] flex flex-row items-center text-[18px] font-manrope font-[500] px-[16px] py-[8px] rounded-[8px] bg-[#202939] gap-[8px] h-[2.4rem]">
                          <img src={Discord} alt="Discord" />
                          {profileDetails.discord_profile}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" gap-[32pxpx] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] px-[24px] py-[32px] shadow-custom xxl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full h-[140px] ">
                    <div className=" flex justify-center">
                      <div className=" border-dashed border-[0.063rem] border-[#364152] w-[480px] h-[36px] flex justify-between  p-2 rounded-[0.5rem]">
                        <div className=" flex ">
                          <img
                            src={linkshare}
                            alt="linkshare/"
                            className=" w-[1rem] h-[1rem] mt-0.5 "
                          />
                          <div className="text-[0.875rem] text-[#9AA4B2] font-manrope font-[400] pl-2">
                            {shortendUrl && shortendUrl}
                          </div>
                        </div>
                        <CopyToClipboard
                          text={shortendUrl}
                          onCopy={() => onCopy()}
                        >
                          <div className="text-[0.875rem] text-[#67FFD1] font-[800] font-manrope cursor-pointer">
                            {t("copylink")}
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className=" flex justify-center pt-2">
                      <ShareSocialLink />
                    </div>
                  </div>
                </div>
                <div className="text-[18px] font-manrope font-[500] text-white mt-[28px] mb-[16px]">
                  {t("badges")}
                </div>

                {badgeDetails && badgeDetails.length > 0 && (
                  <div className="gap-[8px] xxl:p-[48px] xl:p-[48px] lg:p-[48px] md:p-[48px] sm:px-[24px]  sm:py-[32px] xd:px-[24px]  xd:py-[32px] rounded-[16px] flex flex-row justify-start items-center border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] p-[24px] shadow-custom w-full h-full ">
                    {badgeDetails.map((badgeLevel, index) => {
                      if (handleClaimedBadges?.length === index) return 0;
                      return (
                        <div key={index} className="flex flex-col items-center">
                          <img
                            src={badgeLevel.levelIcon}
                            alt={`Level ${badgeLevel.level}`}
                          />
                          <div className="text-[#9AA4B2] text-[14px] font-manrope font-[400] pt-2">
                            {badgeLevel.levelName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}

                <div className="text-[18px] font-manrope font-[500] text-white mt-[28px] mb-[16px]">
                  {t("other_achievements")}
                </div>
                <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col  justify-start items-start gap-[16px]">
                  <div className="gap-[16px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)]  p-[16px] shadow-custom xxl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full h-full ">
                    <div className="text-[14px] font-[manrope] text-[white] text-[400]">
                      {t("completed_missions")}
                    </div>
                    <div className="p-[24px] rounded-[16px] mt-[16px] flex flex-row justify-between items-center backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)]">
                      <div className="text-[18px] font-[manrope] font-[500] text-[#FFF] flex flex-row justify-start items-center gap-[16px]">
                        <img
                          src={membershipbadge}
                          alt="Polygon"
                          className=" w-[22.928px] h-[24.539px]"
                        />
                        {t("sign_up")}
                      </div>
                      <div className="text-[18px] font-[manrope] font-[400] text-[#697586]">
                        20 XP
                      </div>
                    </div>
                    {completedMission.map((mission: any) => {
                      return (
                        <div className="p-[24px] rounded-[16px] mt-[16px] flex flex-row justify-between items-center backdrop-blur-[54px] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)]">
                          <div className="text-[18px] font-[manrope] font-[500] text-[#FFF] flex flex-row justify-start items-center gap-[16px]">
                            <img
                              src={Polygon}
                              alt="Polygon"
                              className=" w-[22.928px] h-[24.539px]"
                            />
                            {mission.mission_title}
                          </div>
                          <div className="text-[18px] font-[manrope] font-[400] text-[#697586]">
                            {mission.points} XP
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className=" rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] p-[24px]   shadow-custom xxl:w-1/2 xl:w-1/2 lg:w-1/2 md:w-full sm:w-full xd:w-full h-full ">
                    <div className="text-[20px] font-[manrope] font-[400] text-[#697586]">
                      {t("successfully_completed")}
                    </div>
                    <div className="text-[36px] font-[manrope] font-[800] text-[white]">
                      {referrals.length}&nbsp; referrals
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {errorMessage !== "" && (
            <Toaster
              message={errorMessage}
              handleCloseToaster={handleCloseToaster}
            />
          )}
        </>
      ) : (
        <>
          <NotFound />
        </>
      )}
      <></>
    </div>
  );
};

export default PublicProfile;
