import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../assets/icons/kana_logo.svg";
import menu from "../assets/icons/menu.svg";
import username from "../assets/icons/username.svg";
import { useStore } from "store";
import { useNavigate } from "react-router-dom";
import linkshare from "../assets/icons/linkshare.svg";
import whatsapp from "../assets/icons/whatsapp.svg";
import telegram from "../assets/icons/telegramcolor.svg";
import image19 from "../assets/icons/image19.svg";
import level from "../assets/icons/Level 01.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import Toaster from "components/Toaster";
import ClickAwayListener from "react-click-away-listener";
import HeaderModal from "./HeaderModal";
import { defaultProfileDetails } from "helper/constants";
import xtwitter from "../assets/icons/Xtwitter.svg";

const Navbar = (props: any) => {
  const { shortendUrl } = props;
  const {
    profileDetails,
    web3Instance,
    updateIsWalletConnected,
    updateProfileDetails,
    updateActiveTab,
    
  } = useStore();
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isOpenHeader, setIsOpenHeader] = useState(false);
  const navigation = useNavigate();

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const Clsoedropdown = () => {
    isOpen && setIsOpen(false);
  };

  const toggleClose = (e: any) => {
    if (e.target.id === "dropdown-options") {
      setIsOpen((prevstate) => {
        return !prevstate;
      });
    }
  };

  const handleHeaderModal = () => {
    setIsOpenHeader((prevstatus) => {
      return !prevstatus;
    });
  };

  const handleLogout = () => {
    web3Instance.logout();
    localStorage.clear();
    updateProfileDetails(defaultProfileDetails);
    updateIsWalletConnected(false);
    navigation("/");
  };

  const onCopy = () => {
    setErrorMessage("Link Copied!");
  };

  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => setErrorMessage(""), 2000);
    }
  }, [errorMessage]);

  const handleGoToProfile = () => {
    updateActiveTab("profile");
  };

  return (
    <>
      <div className="!fixed !top-0  z-[2] font-inter w-[100%] h-[6rem] bg-[#1d1e20] backdrop-blur-[3.125rem] px-[40px] ">
        <div className="flex justify-between items-center pt-4 ">
          <img src={Logo} alt="/" />
          <ul className="flex flex-row justify-center items-center gap-[48px] text-[#697586] text-[1rem] font-[700]">
            <li className="flex flex-row justify-center items-center xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden">
              <img src={level} alt="icon/" />
              <span className=" pl-2 ">{profileDetails?.points} XP</span>
            </li>
            <li
              className=" cursor-pointer flex flex-row justify-center items-center xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden hover:bg-[#0C0C0D] hover:rounded-[0.5rem] w-[10rem]"
              onClick={toggleSelect}
              id="Username-wrapper"
            >
              <img src={username} alt="Username" id="Username-image" />
              <span className=" pl-2 " id="Username-text">
                {profileDetails?.userName}
              </span>
            </li>
            <li
              onClick={() => handleLogout()}
              className=" cursor-pointer flex-row justify-center items-center xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden"
            >
              <span className=" pl-2 hover:bg-[#0C0C0D] hover:rounded-[0.5rem] w-[5rem] h-[2.5rem] flex justify-center items-center xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden  ">
                Logout
              </span>
            </li>
            <li
              className=" cursor-pointer flex flex-row justify-center items-center xxl:hidden xl:hidden lg:hidden md:flex sm:flex xd:flex hover:bg-[#0C0C0D] hover:rounded-[0.5rem] w-[10rem]"
              onClick={() => {
                navigation("/profile-edit");
              }}
              id="Username-wrapper"
            >
              <img
                src={username}
                alt="Username"
                id="Username-image"
                className=" pl-24"
              />
            </li>
            <li
              className=" cursor-pointer flex-row justify-center items-center"
              onClick={() => handleHeaderModal()}
            >
              <img src={menu} alt="menu" id="sandwich-menu" />
            </li>
          </ul>
        </div>
        {isOpen && (
          <ClickAwayListener onClickAway={Clsoedropdown}>
            <div className=" flex justify-end" id="dropdown-visible">
              <div
                onClick={toggleClose}
                id="dropdown-options"
                className=" bg-[#202d32]  absolute rounded-[1rem] text-white xxl:w-[18.75rem] xl:w-[18.75rem] lg:w-[18.75rem] md:w-[31.175rem] sm:w-[14.625rem] xd:w-[24.425rem] xxl:h-[10.5rem] xl:h-[10.5rem] lg:h-[10.5rem] md:h-[8.5rem] sm:h-[8.425rem] xd:h-[8.425rem] top-[6.5rem] right-[0.5rem]"
              >
                <img
                  src={image19}
                  alt="image19"
                  className=" absolute w-full h-full rounded-[1rem]"
                />
                <div className="pt-2 pl-3.5 relative ">
                  <div className=" border-dashed border-[0.063rem] border-[#364152] w-[16.75rem] flex justify-between p-2 rounded-[1rem]">
                    <div className=" flex ">
                      <img
                        src={linkshare}
                        alt="linkshare/"
                        className=" w-[1rem] h-[1rem] mt-1"
                      />
                      <div className="text-[0.875rem] text-[#9AA4B2] font-manrope font-[400] pl-2 truncate w-40">
                        {shortendUrl && shortendUrl}
                      </div>
                    </div>
                    <CopyToClipboard text={shortendUrl} onCopy={() => onCopy()}>
                      <div className="text-[0.875rem] text-[#67FFD1] font-[800] font-manrope cursor-pointer">
                        {t("copylink")}
                      </div>
                    </CopyToClipboard>
                  </div>
                  <div className=" text-[0.875rem] text-[#9AA4B2] flex justify-between p-[6%_16%]">
                    <div> {t("share_using")}</div>
                    <TelegramShareButton url={shortendUrl}>
                      <img
                        src={telegram}
                        alt="telegram"
                        className=" cursor-pointer"
                      />
                    </TelegramShareButton>
                    <WhatsappShareButton url={shortendUrl}>
                      <img
                        src={whatsapp}
                        alt="whatsapp"
                        className=" cursor-pointer"
                      />
                    </WhatsappShareButton>
                    <TwitterShareButton url={shortendUrl}>
                      <div className=" w-5 h-4 cursor-pointer">
                        <img src={xtwitter} alt="xtwitter" />
                        {/* <TwitterIcon size={23} round /> */}
                      </div>
                    </TwitterShareButton>
                  </div>
                  <div
                    className=" flex justify-center"
                    onClick={() => handleGoToProfile()}
                  >
                    <button
                      onClick={() => navigation("/private-profile")}
                      className="blue_green_gradient_5 w-[16.75rem] h-[2.875rem] blue_green_gradient_3 rounded-[1rem]  bg-gradient-to-r from-[#4f9bff1a] to-[#67ffd11a] flex justify-center items-center gradient-border"
                    >
                      <span className="text-[0.875rem] text-[#67FFD1] font-[400] font-manrope">
                        {t("go_to_my_profile")}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
      {errorMessage !== "" && (
        <Toaster
          message={errorMessage}
          handleCloseToaster={handleCloseToaster}
        />
      )}
      {isOpenHeader && <HeaderModal handleCloseHeader={handleHeaderModal} />}
    </>
  );
};

export default Navbar;
