import React, { useCallback, useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import { useTranslation } from "react-i18next";
import user2 from "../../assets/icons/user2.svg";
import stack3 from "../../assets/icons/stack3.svg";
import speaker from "../../assets/icons/speaker.svg";
import linkshare from "../../assets/icons/linkshare.svg";
import { useStore } from "store";
import {
  generateShortURL,
  getNumberOfReferrals,
  handleGetBadgeDetails,
  handleGetClaimFromDB,
  handleGetLevelBasedBadges,
  handleGetUserDetails,
  handleMintMemberShipNFT,
  handleUpdateClaimInDB,
  handleWhitelist,
} from "helper/reusableMethods";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ComingSoon from "../ComingSoon";
import Toaster from "../../components/Toaster";
import gameonboard from "../../assets/icons/Game Onboard Badge.svg";
import image19 from "../../assets/icons/image19.svg";
import basepic3 from "../../assets/icons/basepic3.svg";
import ShareSocialLink from "components/ShareSocialLink";
import LeaderBoard from "./LeaderBoard";
import Missions from "./Missions";
import { api } from "helper/constants";
import UpLeftArrow from "../../assets/icons/UpLeftArrow.svg";
import Loader from "../Loader";
import MembershipbadgeDisplay from "pages/MembershipbadgeDisplay";
import _ from "lodash";

const GameDashboard = () => {
  const { t } = useTranslation();
  const {
    profileDetails,
    updateProfileDetails,
    address,
    sdk,
    badges,
    updateBadges,
    claimedBadges,
    updateClaimedBadges
  } = useStore();
  const [shortendUrl, setShortendUrl] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("home");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [numberOfReferrals, setNumberOfReferrals] = useState<any>();
  const [claimMembershipDone, setClaimMemberShipDone] =
    useState<boolean>(false);
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [isMemberShipModalOpen, setIsMemberShipModalOpen] =
    useState<boolean>(false);
  const [modalBadge, setModalBadge] = useState<any>();

  const shortenerUrl = useCallback(async () => {
    if (profileDetails && profileDetails.referralLink === "") {
      let link = `https://play.kanalabs.io/?address=${profileDetails?.userWallet}`;
      const response: any = await generateShortURL(link);
      setShortendUrl(response?.short_url);
      const addReferralLinkResponse = await api.post(`/addreferrallink`, {
        referralId: profileDetails?.userWallet,
        userWallet: profileDetails?.userWallet,
        referralLink: response?.short_url,
      });
      if (addReferralLinkResponse.status === 200) {
        const { error, userDetails } = await handleGetUserDetails(
          profileDetails?.userWallet
        );
        if (!error) {
          updateProfileDetails(userDetails);
        }
      }
    } else {
      setShortendUrl(profileDetails?.referralLink);
    }
  }, [profileDetails, updateProfileDetails]);

  const handleGetNumberOfReferrals = useCallback(async () => {
    const { numberOfReferral } = await getNumberOfReferrals(
      profileDetails?.userWallet
    );
    setNumberOfReferrals(numberOfReferral);
  }, [profileDetails?.userWallet]);

  const handleGetBadges = useCallback(async () => {
    if (sdk && profileDetails.userWallet !== "") {
      const { error, badges } = await handleGetBadgeDetails(
        profileDetails.userWallet,
        sdk
      );
      if (!error) {
        const klBadges = badges.items.filter(
          (badge: any) => badge.contractSymbol === "KL"
        );
        const memberShipBadge = klBadges?.filter((badge: any) => badge.contractAddress === '0xd79c70f80bb9bca56220ba2eadc2315073e2deec');
        if (memberShipBadge.length > 0) {
          setClaimMemberShipDone(true);
        }
        const levelBadgesArray = klBadges?.filter((badge: any) => badge.contractAddress === '0x0f7c89ad29ff85e3b21d15d7c7d59d11cc268642');
        if (levelBadgesArray.length > 0) {
          const levelBadges = levelBadgesArray[0]?.items
        }
        const { error, status } = await handleGetClaimFromDB(profileDetails?.userWallet)
        if (!error) {
          if (status?.data?.userDetails.length > 0) {
            console.log("")
          }
        }
      }
    }
  }, [profileDetails?.userWallet, sdk]);

  useEffect(() => {
    shortenerUrl();
    handleGetNumberOfReferrals();
  }, [
    handleGetNumberOfReferrals,
    profileDetails,
    shortenerUrl,
    sdk,
  ]);

  const handleActiveTab = (tab: string) => {
    setActiveTab(tab);
  };

  const handleCloseComingSoon = () => {
    setActiveTab("home");
  };
  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  const onCopy = () => {
    setErrorMessage("Link Copied!");
  };

  const handleCloseMembershipbadgeDisplay = () => {
    setIsMemberShipModalOpen(false);
  };
  const handleLevelBadgeClaim = async () => {
    setIsLoading(true);
    const { error, isWhitelisted } = await handleWhitelist(address, profileDetails?.currentLevel);
    if (error) {
      setErrorMessage("Unable to claim Badge please try again");
      setIsLoading(false);
      return;
    }
    if (isWhitelisted) {
      const { error, hash } = await handleGetLevelBasedBadges(profileDetails?.userWallet, sdk, profileDetails?.currentLevel);
      if (!error) {
        updateBadges(badges);
        await handleGetBadges()
        const { error, status } = await handleUpdateClaimInDB(profileDetails?.userWallet, profileDetails?.currentLevel)
        setErrorMessage(`Successfully claimed ${profileDetails?.currentLevel} Badge`);
        setIsLoading(false);
        return
      } else {
        setIsLoading(false);
        setErrorMessage("Unable to claim Level Badge please try again");
        return;
      }
    }
  }

  const handleClaim = async () => {
    setIsLoading(true);
      const { error } = await handleWhitelist(address, claimedBadges?.length);
      if (error) {
        setErrorMessage("Unable to claim Badge please try again");
        setIsLoading(false);
        return;
      }
      if(claimedBadges?.length === 0 ){
        const { error: errorFromMintMemberShipNFT, hash } = await handleMintMemberShipNFT(sdk);
        if(errorFromMintMemberShipNFT){
          setIsLoading(false);
          setErrorMessage("Unable to claim Membership Badge please try again");
          return;
        }
      }else{
        const { error, hash } = await handleGetLevelBasedBadges(profileDetails?.userWallet, sdk, claimedBadges?.length);
        if(error){
          setIsLoading(false);
          setErrorMessage(`Unable to claim ${claimedBadges?.length} Badge please try again`);
          return;
        }
  
      }
      await handleUpdateClaimInDB(profileDetails?.userWallet, claimedBadges?.length)
      const {  badges } = await handleGetBadgeDetails(address, sdk);
      if(badges?.length > 0){
        const kanaBadge = badges.items.filter((badge: any) => badge.contractSymbol === 'KL')
        updateBadges(kanaBadge)
      }
      const { status} = await handleGetClaimFromDB(address)
      updateClaimedBadges(status)
      localStorage.setItem("claimedBadges", JSON.stringify(status))
      setIsLoading(false);
      setErrorMessage(`Badge Claimed successfully`);
  };

  return (
    <>
      <div>
        <Navbar shortendUrl={shortendUrl && shortendUrl} />
        {activeTab === "home" ? (
          <>
            <div className="flex flex-row justify-start items-start">
              <div className="xxl:inline xl:inline lg:inline md:hidden sm:hidden xd:hidden ">
                <Sidebar handleActiveTab={handleActiveTab} />
              </div>
              <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[14rem] container-fluid z-[1] bg-[#111213]">
                <div className="xxl:m-[24px] xl:m-[24px] lg:m-[24px] md:m-[16px] sm:m-[16px] xd:m-[16px] !mt-[5.5rem] mb-[2rem]">
                  <div className=" pt-8 gap-[16px]">
                    <div className=" xxl:w-full xl:w-full lg:w-auto md:w-auto sm:w-auto xd:w-auto  main-content gap-[16px] shadow-custom">
                      <img
                        src={image19}
                        alt="image19"
                        className=" absolute w-full h-full rounded-[1rem]"
                      />
                      <div className=" flex xxl:justify-between xl:justify-between lg:justify-between md:justify-between sm:justify-center xd:justify-center relative">
                        <div className=" xxl:pl-16 xl:pl-16 lg:pl-16 md:pl-10">
                          <div className=" text-[#7D8595] uppercase text-[0.875rem] font-[700] font-manrope  xxl:pt-16 xl:pt-16 lg:pt-16 md:pt-16 sm:pt-6 xd:pt-6">
                            {t("kana_gaming_alliance")}
                          </div>
                          <div className=" xxl:text-[2.25rem] xl:text-[2.25rem] lg:text-[2.25rem] md:text-[2.25rem] sm:text-[1.5rem] xd:text-[1.5rem] text-[#FFFFFF] font-urbanist xxl:w-[28.938rem] xl:w-[28.938rem] lg:w-[24.938rem] md:w-[24.938rem] sm:w-[16.375rem] xd:w-[16.375rem] pt-2 ">
                            {claimedBadges?.length === 0 ?
                               "Claim Your MemberShip Badge" : 
                               profileDetails?.currentLevel === (claimedBadges?.length - 1) ? "Complete Your Missions and Earn More XPs" :  `Congrats You Completed level ${claimedBadges?.length} Please claim your badge`
                             }
                          </div>
                         { ((claimedBadges?.length === 0 ) || (profileDetails?.currentLevel > (claimedBadges?.length - 1))) &&    <div className=" flex justify-between">
                              <button
                                onClick={() => handleClaim()}
                                className="  xxl:w-[13rem] xl:w-[13rem] lg:w-[13rem] md:w-[13rem] sm:w-[13rem] xd:w-[9rem] h-[2.875rem] rounded-[1rem] blue_green_gradient_5 bg-gradient-to-r from-[#4f9bff1a] to-[#67ffd11a] flex justify-center items-center gradient-border xxl:mt-4 xl:mt-4 lg:mt-4 md:mt-4 sm:mt-2 xd:mt-2 mb-8"
                              >
                                <span className="text-[0.875rem] text-[#67FFD1] font-[400] font-manrope">
                                  {t("Claim")}
                                </span>
                                <img
                                  src={UpLeftArrow}
                                  alt="UpLeftArrow"
                                  className=" ml-2 "
                                />
                              </button>
                              <img
                                src={gameonboard}
                                alt="gameonboard"
                                className="w-[8rem] xxl:hidden xl:hidden lg:hidden md:hidden sm:flex xd:flex ml-8"
                              />
                            </div> }
                        </div>
                        <div className=" bg_dashboard bg-no-repeat bg-contain w-[266.844px] h-[266.844px] mt-3 xxl:mr-32 xl:mr-32 lg:mr-4 md:mr-[10rem] xxl:flex xl:flex lg:flex md:flex sm:hidden xd:hidden">
                          <div className="bg_dashboard3 w-[2]">
                            <div className=" bg_dashboard2 bg-no-repeat bg-contain w-[272.956px] h-[272.956px] flex justify-center">
                              <img
                                src={basepic3}
                                alt="basepic3"
                                className=" w-[126.667px] h-[164.683px] mt-9"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" pt-4 w-full ">
                    <div className=" xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col w-full ">
                      <Missions />
                      <LeaderBoard />
                    </div>
                  </div>
                  <div className=" pt-4 w-full">
                    <div className=" xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col w-full  ">
                      <div className=" xxl:w-1/2  xl:w-1/2 lg:w-1/2 md:w-auto sm:w-auto xd:w-auto">
                        <div className="xxl:w-full xl:w-full lg:w-auto md:w- sm:w-auto xd:w-auto h-[7.625rem] rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] pl-4 pt-3">
                          <div className=" text-[1.25rem] font-manrope font-[400] text-[#697586]">
                            {t("you_have_sucessfully_completed")}
                          </div>
                          <div
                            className=" text-[2.25rem] font-manrope font-[800] text-white"
                            id="referrals-id"
                          >
                            {numberOfReferrals}&nbsp; Referrals
                          </div>
                        </div>
                        <div className="xxl:w-full xl:w-full lg:w-auto md:w-auto sm:w-auto xd:w-auto h-[7.625rem] rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] mt-3 pl-4 pt-3">
                          <div className=" text-[1.25rem] font-manrope font-[400] text-[#697586]">
                            {t("total_xp_earned")}
                          </div>
                          <div className=" text-[2.25rem] font-manrope font-[800] text-white">
                            {numberOfReferrals * 20}
                          </div>
                        </div>
                      </div>
                      <div className=" xxl:mt-0 xl:mt-0 lg:mt-0 md:mt-8 sm:mt-8 xd:mt-8 xxl:ml-8 xl:ml-8 lg:ml-2 md:ml-0 sm:ml-0 xd:ml-0 xxl:w-1/2  xl:w-1/2 lg:w-1/2 md:w-auto sm:w-auto xd:w-auto">
                        <div className="xxl:w-full xl:w-full lg:w-auto md:w-full sm:w-auto xd:w-auto h-[22rem] bg-black rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f]">
                          <div className=" flex justify-between xxl:p-[7%_12%] xl:p-[10%_12%] lg:p-[10%_12%] md:p-[8%_12%] sm:p-[10%_12%] xd:p-[10%_12%]  ">
                            <div className="bg-[#1D1E20] rounded-[0.5rem] w-[3rem] h-[3rem] flex justify-center items-center">
                              <img src={speaker} alt="speaker" />
                            </div>
                            <div className=" xxl:w-[8rem] xl:w-[8rem] lg:w-[8rem] md:w-[8rem] sm:w-[4rem] xd:w-[4rem] h-[0.1rem] bg-[#202939] rounded-full mt-4"></div>
                            <div className="bg-[#1D1E20] rounded-[0.5rem] w-[3rem] h-[3rem] flex justify-center items-center">
                              <img src={user2} alt="speaker" />
                            </div>
                            <div className=" xxl:w-[8rem] xl:w-[8rem] lg:w-[8rem] md:w-[8rem] sm:w-[4rem] xd:w-[4rem] h-[0.1rem] bg-[#202939] rounded-full mt-4"></div>
                            <div className="bg-[#1D1E20] rounded-[0.5rem] w-[3rem] h-[3rem] flex justify-center items-center">
                              <img src={stack3} alt="speaker" />
                            </div>
                          </div>
                          <div className=" flex justify-between xxl:p-[0%_8%] xl:p-[0%_8%] lg:p-[4%_4%] md:p-[0%_8%] sm:p-[0%_6%] xd:p-[0%_6%] xxl:mt-[-3rem] xl:mt-[-3rem] lg:mt-[-3rem] md:mt-[-3rem] sm:mt-[-2rem] xd:mt-[-2rem]">
                            <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem]">
                              {t("share_your_invite")}
                            </div>
                            <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem] xxl:pr-6 xl:pr-6 lg:pr-0 md:pr-6 sm:pr-0 xd:pr-0">
                              {t("your_friend_joins")}
                            </div>
                            <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem] xxl:pr-3 xl:pr-3 lg:pr-0 md:pr-3 sm:pr-0 xd:pr-0">
                              {t("you_get_xp")}
                            </div>
                          </div>
                          <div className=" xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex xxl:flex-row xl:flex-row lg:flex-col md:flex-row sm:flex-col xd:flex-col justify-around p-16">
                            <div className=" border-dashed border-[0.063rem] border-[#364152] w-auto h-[2.25rem] rounded-[0.5rem] flex items-center justify-between xxl:p-[1%_4%] xl:p-[1%_4%] lg:p-[1%_4%] md:p-[1%_4%] sm:p-[1%_2%] xd:p-[1%_2%]">
                              <div className=" flex">
                                <img
                                  src={linkshare}
                                  alt="linkshare/"
                                  className=" w-[1rem] h-[1rem] mt-1"
                                />
                                <div className="text-[0.875rem] text-[#9AA4B2] font-manrope font-[400] pl-2 truncate w-40">
                                  {shortendUrl && shortendUrl}
                                </div>
                              </div>
                              <CopyToClipboard
                                text={shortendUrl && shortendUrl}
                                onCopy={() => onCopy()}
                              >
                                <div className="text-[0.875rem] text-[#67FFD1] font-[800] font-manrope cursor-pointer">
                                  {t("copylink")}
                                </div>
                              </CopyToClipboard>
                            </div>
                            <ShareSocialLink />
                            {isloading && <Loader />}
                            {/* {isMemberShipModalOpen && (
                              <MembershipbadgeDisplay
                                modalBadge={modalBadge}
                                handleClose={handleCloseMembershipbadgeDisplay}
                              />
                            )} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {errorMessage !== "" && (
              <Toaster
                message={errorMessage}
                handleCloseToaster={handleCloseToaster}
              />
            )}
          </>
        ) : (
          <ComingSoon handleClose={handleCloseComingSoon} />
        )}
      </div>
    </>
  );
};

export default GameDashboard;
