import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import RecieptCheck from "../../assets/icons/receipt-check.svg";
import ShoppingBag from "../../assets/icons/shopping-bag.svg";
import Star from "../../assets/icons/star.svg";
import BankNote from "../../assets/icons/bank_note.svg";
import Coins from "../../assets/icons/coins.svg";

const Rewards = () => {
    const { t } = useTranslation();

    const SCROLL_SPEED = 2.2;
    const [scrollX, setScrollX] = useState(0);
    useEffect(() => {
      const handleScroll = () => {
        setScrollX(window.scrollY / SCROLL_SPEED);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return <>
      <div className=" my-[80px]">
            <div className="xxl:!ml-[302px] xl:!ml-[162px] lg:!ml-[75px] md:!ml-[20px]   sm:!ml-[20px]   xd:!ml-[20px]   text-[36px] text-[white] font-urbanist font-[700] mb-[35px] ">
              {t("rewards")}
            </div>
            <div className="flex flex-row overflow-x-scroll flex-nowrap origin-left justify-start items-start gap-[20px] horizontal-scroll-container ">
              <div
                className="scroll_content flex flex-row flex-nowrap  origin-left justify-start items-start gap-[20px] scroll-item xxl:!ml-[562px] xl:!ml-[522px] lg:!ml-[615px] md:!ml-[720px] sm:!ml-[720px]   xd:!ml-[720px]   horizontal-scroll-content"
                style={{ transform: `translateX(-${scrollX}px)` }}
              >
                <div className="  w-[400px] h-[232px] font-manrope text-[15px] py-[24px] px-[32px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom ">
                  <img
                    src={RecieptCheck}
                    alt="reciept check"
                    className="mb-[10px]"
                  />
                  <div className="text-[white] font-[700] py-[14px]">
                    {t("coupon_codes")}
                  </div>
                  <div className="text-[#7D8595] font-[400]">
                    {t("enjoy_irresistable")}
                  </div>
                </div>
                <div className="w-[400px] h-[232px] font-manrope text-[15px] py-[24px] px-[32px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom ">
                  <img
                    src={ShoppingBag}
                    alt="ShoppingBag"
                    className="mb-[10px]"
                  />
                  <div className="text-[white] font-[700] py-[14px]">
                    {t("amazon_gift")}
                  </div>
                  <div className="text-[#7D8595] font-[400]">
                    {t("stop_till_you_drop")}
                  </div>
                </div>
                <div className="w-[400px] h-[232px] font-manrope text-[15px] py-[24px] px-[32px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom ">
                  <img src={Star} alt="Star" className="mb-[10px]" />
                  <div className="text-[white] font-[700] py-[14px]">
                    {t("in_game_nfts")}
                  </div>
                  <div className="text-[#7D8595] font-[400] ">
                    {t("gain_a_competitive")}
                  </div>
                </div>
                <div className="w-[400px] h-[232px] font-manrope text-[15px] py-[24px] px-[32px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom ">
                  <img src={Coins} alt="Coins" className="mb-[10px]" />
                  <div className="text-[white] font-[700] py-[14px]">
                    {t("monthly_in_house")}
                  </div>
                  <div className="text-[#7D8595] font-[400]">
                    {t("unlock_the_chance")}
                  </div>
                </div>
                <div className="w-[400px] h-[232px] xxl:!mr-[302px] xl:!mr-[162px] lg:!mr-[75px] md:!mr-[20px]   sm:!mr-[20px]   xd:!mr-[20px]  font-manrope text-[15px] py-[24px] px-[32px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom ">
                  <img src={BankNote} alt="BankNote" className="mb-[10px]" />
                  <div className="text-[white] font-[700] py-[14px]">
                    {t("giveaway_galore")}
                  </div>
                  <div className="text-[#7D8595] font-[400]">
                    {t("you_will_get")}
                  </div>
                </div>
              </div>
            </div>
          </div></>
}

export default Rewards;