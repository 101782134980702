import React, { useEffect } from "react";

const AuthPopup = () => {
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    if (currentUrl.searchParams.has("authStatus")) {
      window.opener.postMessage({
        eventType: "auth status",
        authStatus: currentUrl.searchParams.get("authStatus"),
        platform: "steam",
      });
    }
    if (currentUrl.searchParams.has("oauth_token")) {
      window.opener.postMessage({
        eventType: "twitter oauth",
        oauth_token: currentUrl.searchParams.get("oauth_token"),
        oauth_verifier: currentUrl.searchParams.get("oauth_verifier"),
      });
    }
    window.onbeforeunload = (ev) => {
      window.opener.postMessage({
        eventType: "unload",
      });
    };
  }, []);
  return (
    <div className="text-white w-screen h-screen flex flex-column items-center justify-center">
      <h1 className="text-xl mb-3">Thank you for authenticating!</h1>
      <p>Taking you back to KanaLabs Gaming...</p>
    </div>
  );
};

export default AuthPopup;
