import "./App.css";
import React, { Suspense, useEffect, useCallback } from "react";
import "./i18n";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUsTraders from "./pages/ContactUStraders";
import ContactUsDev from "./pages/ContactUS";
import NotFound from "./pages/NotFound";
import GamePlay from "./pages/GameLanding.tsx/index";
import { useStore } from "./store";
import { WebAuthInit } from "./WebAuth";
import PublicProfile from "./pages/PublicProfile";
import GameDashboard from "./pages/GameDashboard/index";
import PrivateProfile from "pages/PrivateProfile";
import SteamPopup from "pages/SteamPopup";
import ProtectedRoute from "ProtectedRoute";
import {
  handleGetBadgeDetails,
  handleGetClaimFromDB,
  handleGetUserDetails,
  handleInitWallet,
} from "helper/reusableMethods";
import AuthPopup from "components/AuthPopup";
import DiscordMissionPopup from "components/DiscordMissionPopup";

const App = () => {
  const {
    updateWeb3Instance,
    updateProfileDetails,
    updateAddress,
    updateSdk,
    updateBadges,
    updateClaimedBadges
  } = useStore();

  const handleUpdateUserDetails = useCallback(
    async (web3Instance: any) => {
      const userDetailsFromLocal = localStorage.getItem("userDetails");
      const claimedBadgesFromLocal = localStorage.getItem('claimedBadges');
      if (userDetailsFromLocal != null && claimedBadgesFromLocal != null) {
        updateProfileDetails(JSON.parse(userDetailsFromLocal));
        updateClaimedBadges(JSON.parse(claimedBadgesFromLocal))
      }
      const { status, sdk, address } = await handleInitWallet(web3Instance);
      if (!status) {
        const { badges } = await handleGetBadgeDetails(address, sdk);
        if (badges?.length > 0) {
          const kanaBadge = badges.items.filter((badge: any) => badge.contractSymbol === 'KL')
          updateBadges(kanaBadge)
        }
        updateSdk(sdk);
        updateAddress(address);
        const { status } = await handleGetClaimFromDB(address)
        updateClaimedBadges(status)
        const { error, userDetails } = await handleGetUserDetails(address);
        if (!error) {
          const { error, badges } = await handleGetBadgeDetails(
            userDetails.userWallet,
            sdk
          );
          if (!error) {
            updateBadges(badges);
          }
          updateProfileDetails(userDetails);
        }
      }
    },
    [updateAddress, updateBadges, updateProfileDetails, updateSdk, updateClaimedBadges]
  );

  const socialLoginInit = useCallback(async () => {
    const { error, web3Instance } = await WebAuthInit();
    if (error) return;
    updateWeb3Instance(web3Instance);
    if (web3Instance?.status === "connected") {
      handleUpdateUserDetails(web3Instance);
    }
  }, [handleUpdateUserDetails, updateWeb3Instance]);

  useEffect(() => {
    socialLoginInit();
  }, [socialLoginInit]);

  return (
    <>
      <Suspense fallback={null}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <GamePlay />
                </ProtectedRoute>
              }
            />
            <Route path="/steam" element={<SteamPopup />} />
            <Route path="/contact-us-dev" element={<ContactUsDev />} />
            <Route path="/contact-us-traders" element={<ContactUsTraders />} />
            <Route path="/game-play" element={<GamePlay />} />
            <Route path="*" element={<NotFound />} />
            <Route path="/:fullName" element={<PublicProfile />} />
            <Route
              path="/game-dashboard"
              element={
                <ProtectedRoute>
                  <GameDashboard />
                </ProtectedRoute>
              }
            />
            <Route path="/public-profile" element={<PublicProfile />} />
            <Route path="/private-profile" element={<PrivateProfile />} />
            {/* POP-UP ROUTES */}
            <Route path="/auth-return" element={<AuthPopup />} />
            <Route path="/discord-mission" element={<DiscordMissionPopup />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
};

export default App;
