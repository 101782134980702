import React, { useState } from "react";
import home from "../assets/icons/home.svg";
import gamingpad from "../assets/icons/gamingpad.svg";
import trophy from "../assets/icons/trophy.svg";
import mission from "../assets/icons/mission.svg";
import community from "../assets/icons/community.svg";
import referral2 from "../assets/icons/referral2.png";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";

interface SideBarInterface {
  handleActiveTab(tabName: string): void;
}

const tabs = [
  { id: "home", label: "Home", img: home },
  { id: "all games", label: "All Games", img: gamingpad },
  { id: "achievements", label: "Achievements", img: trophy },
  { id: "missions", label: "Missions", img: mission },
  { id: "community", label: "Community", img: community },
  { id: "referral", label: "Referral", img: referral2 },
];

const Sidebar = (porps: SideBarInterface) => {
  const { handleActiveTab } = porps;
  const { activeTab, updateActiveTab } = useStore();
  const [activeTabLocal, setActiveTab] = useState(activeTab);
  const navigation = useNavigate();

  const handleTabClick = (tabName: any) => {
    setActiveTab(tabName);
    handleActiveTab(tabName);
    if (tabName === "home") {
      navigation("/game-dashboard");
      updateActiveTab("home");
    }
  };
  
  return (
    <div className="w-full  h-screen fixed top-[6rem] !z-[1] transition-transform duration-300 ease-in items-start xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden ">
      <div className=" xxl:w-[20rem] xl:w-[20rem] lg:w-[15rem] md:w-full sm:w-full xd:w-full max-sm:w-full bg-[#1d1e20] h-screen font-inter">
        <ul className="text-[white] text-[1rem] pt-8 px-[16px] ">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => {
                handleTabClick(tab.id);
              }}
              className={`my-1  opacity-[0.5] hover:opacity-[1] hover:bg-[#2b2b2d] hover:text-[white] hover:rounded-[1rem] rounded-[1rem] h-[3.5rem] w-full flex items-center pl-10 cursor-pointer ${
                activeTabLocal === tab.id ? "bg-[#2b2b2d] opacity-[1]" : ""
              }`}
            >
              <img src={tab.img} alt="/" />
              <span className=" pl-4">{tab.label}</span>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
