import React, { useState, useRef, useEffect, useCallback } from "react";
import Logo from "../../assets/icons/kana_logo.svg";
import Mobile_menu from "../../assets/icons/menu.svg";
import { useTranslation } from "react-i18next";
import Game_bg_mob from "../../assets/videos/game_bg.mp4";
import SubmitArrow from "../../assets/icons/SubmitArrow.svg";
import BlackPlaystation from "../../assets/icons/black_playstation.svg";
import Footer from "../Footer";
import WaitListSocialLogin from "../WaitListSocialLogin";
import ProfileSetUpOne from "./ProfileSetUpOne";
import ProfileSetUpThree from "./ProfileSetUpThree";
import { useNavigate } from "react-router-dom";
import { useStore } from "store";
import Loader from "../Loader";
import WhyJoinKanaLabs from "./WhyJoinKanaLabs";
import Rewards from "./Rewards";
import HeaderModal from "components/HeaderModal";
import { api } from "helper/constants";
import { handleGetBadgeDetails, handleGetClaimFromDB, handleGetUserDetails } from "helper/reusableMethods";

const GamePlay = () => {
  const { profileDetails, web3Instance, updateProfileDetails, sdk, updateBadges, updateClaimedBadges } = useStore();
  const [profileOne, setProfileOne] = useState(false);
  const [profileThree, setProfileThree] = useState(false);
  const { t } = useTranslation();
  const [isOpenHeader, setIsOpenHeader] = useState(false);
  const [isWaitlistOpen, setIsWaitlistOpen] = useState(false);
  const videoRef: any = useRef(null);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [referrarWallet, setReferrarWallet] = useState<string>("");
  const navigation = useNavigate();

  const handleHeaderModal = () => {
    setIsOpenHeader(!isOpenHeader);
  };

  const handleAddReferralRecord = async () => {
    const data = {
      referralId: profileDetails?.userWallet,
      referrerWallet: referrarWallet,
      referredWallet: profileDetails.userWallet,
    };
    const response = await api
      .post("/referralRecords", data)
      .catch((e) => console.log(""));
  };

  const handleReferralIfURLHasAddress = useCallback(async () => {
    try {
      const params = new URLSearchParams(window.location.search);
      const addressReferredByFromParams: string | null = params.get("address");
      if (addressReferredByFromParams)
        setReferrarWallet(addressReferredByFromParams);
    } catch (e) {
      console.log("");
    }
  }, []);

  useEffect(() => {
    handleReferralIfURLHasAddress();
  }, [handleReferralIfURLHasAddress]);

  useEffect(() => {
    videoRef.current.play();
  }, []);

  const handleWaitlistOpen = () => {
    setIsWaitlistOpen(!isWaitlistOpen);
  };

  const handleUpdateProfileDetails = async () => {
    const addUserDetails: any = await api
      .post("/userDetails", profileDetails)
      .catch((e) => console.log(""));
    if (addUserDetails?.status === 200) {
      const { error, badges } = await handleGetBadgeDetails(profileDetails.userWallet, sdk)
      if (!error) {
        updateBadges(badges)
      }
      const { error: errorFromHandleGetUserDetails, userDetails } = await handleGetUserDetails(profileDetails.userWallet);
      const { status } = await handleGetClaimFromDB(profileDetails.userWallet)
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      localStorage.setItem("claimedBadges", JSON.stringify(status))
      updateProfileDetails(userDetails);
      await handleAddReferralRecord();
      navigation("/game-dashboard");
    } else {
      web3Instance.logout();
    }
  };

  const handleSocialLoginModelClose = async (
    status: boolean,
    address: string
  ) => {
    if (status) {
      setIsWaitlistOpen(false);
      return;
    }
    const { error, userDetails } = await handleGetUserDetails(address);
    setIsLoaderVisible(false);
    if (!error) {
      const { error, badges } = await handleGetBadgeDetails(userDetails.userWallet, sdk)
      if (!error) {
        if (badges?.length > 0) {
          const kanaBadge = badges.items.filter((badge: any) => badge.contractSymbol === 'KL')
          updateBadges(kanaBadge)
        }
      }
      const { status } = await handleGetClaimFromDB(address)
      updateClaimedBadges(status)
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
      localStorage.setItem("claimedBadges", JSON.stringify(status))
      updateProfileDetails(userDetails);
      setIsWaitlistOpen(false);
      await handleAddReferralRecord();
      navigation("/game-dashboard");
      return;
    }
    setIsWaitlistOpen(false);
    setProfileOne(true);
  };

  const handleProfileSetupOneModelClose = () => {
    setProfileOne(false);
    setProfileThree(true);
  };

  const handleProfileSetupThreeModelClose = async (status: boolean) => {
    if (status) {
      setIsLoaderVisible(true);
      await handleUpdateProfileDetails();
      setProfileThree(false);
      setIsLoaderVisible(false);

      return;
    }
    setProfileThree(false);
    setProfileOne(true);
  };

  const handleSubmit = (e: any) => {
    const form = e.target;
    const submitButton = form.querySelector("#submit");
    const scriptURL =
      "https://script.google.com/macros/s/AKfycbxiArqcZowCjIPLSkxD0jARpw1LujPpJMfn2i5d2EZeIvnUvwxxfY5BTW04oleBgFv1/exec";
    submitButton.disabled = true;
    e.preventDefault();
    let requestBody = new FormData(form);
    fetch(scriptURL, { method: "POST", body: requestBody })
      .then((response) => {
        alert(`Thank you, we have received your email! ${response}`);
        submitButton.disabled = false;
      })
      .catch((error) => {
        alert(
          `Oops! Error in receiving your email, Please try again ${error.message}`
        );
        submitButton.disabled = false;
      });
  };

  useEffect(() => {
    if (web3Instance.status === "connected") {
      const address: any = localStorage.getItem("address");
      if (address && address.length > 0) navigation("/game-dashboard");
    }
  }, [navigation, web3Instance]);

  return (
    <>
      <div className=" relative ">
        <div className="!fixed w-full top-0 text-[white] h-[96px] !z-[2] flex flex-row justify-between items-center py-[30px] xxl:px-[48px] xl:px-[48px] lg:px-[48px] md:px-[48px] sm:px-[20px] xd:px-[20px] !bg-[rgba(29,30,32,0.1)] backdrop-blur-[50px] ">
          <div className="xxl:flex-[0.2] xl:flex-[0.2]  lg:flex-[0.2] md:flex-[0.2] sm:flex-[0.9] xd:flex-[0.9]">
            <a href="https://kanalabs.io/">
              <img src={Logo} alt="kana_logo" />
            </a>
          </div>
          <div className="flex-[0.6] xxl:flex xl:flex lg:flex md:flex  sm:hidden xd:hidden flex-row justify-center items-center text-[white] text-[16px] font-manrope font-[600] gap-[34px]"></div>
          <div className="flex-[0.2]  flex flex-row justify-between items-center">
            <button
              onClick={handleWaitlistOpen}
              id="submit"
              type="submit"
              className=" xxl:flex xl:flex lg:flex md:flex sm:hidden xd:hidden w-[111px] mx-8  cursor-pointer border-none blue_green_gradient  bg-gradient-to-r from-[#4F9DFF1A] to-[#67FFD11A] flex justify-center items-center   h-[3rem]  bg-[#1D1E20]  border-2 text-[1rem]  !text-[rgba(103,255,209,1)] px-3"
            >
              {t("start_now")}
            </button>
            <div
              className="flex flex-row justify-end items-end"
              onClick={() => handleHeaderModal()}
            >
              <img
                src={Mobile_menu}
                alt="Mobile_menu"
                className=" cursor-pointer"
              />
            </div>
          </div>
        </div>
        {isOpenHeader && <HeaderModal handleCloseHeader={handleHeaderModal} />}
        <div>
          <div className="erase_section relative flex justify-center items-center ">
            <div className=" flex items-center justify-center h-full w-full blur_section  overflow-hidden">
              <video
                ref={videoRef}
                className="xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden  w-full h-full !mix-blend-lighten  !object-cover mask-image-radial-gradient"
                autoPlay
                muted
                loop
              >
                <source src={Game_bg_mob} />
              </video>
              <video
                ref={videoRef}
                className=" xxl:hidden xl:hidden lg:hidden md:flex sm:flex xd:flex  w-full h-full !mix-blend-lighten object-cover mask-image-radial-gradient"
                autoPlay
                muted
                loop
              >
                <source src={Game_bg_mob} />
              </video>
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-[#1D1E20] pointer-events-none mask-image-radial-gradient"></div>
            </div>
            <div className="absolute  !flex flex-col !top-20 !justify-center !items-center bg-auto bg-center !backdrop-filter-[150px] bg-no-repeat w-auto h-auto pt-[184px] pb-[118px]   sm:px-[20px] xd:px-[20px]">
              <div className="xxl:text-[84px] xl:text-[84px] lg:text-[84px] md:text-[60px] sm:text-[60px] text-center xd:text-[60px] font-[800] text-[#FFFFFF] leading-none   h-auto font-urbanist uppercase xxl:px-[140px] xl:px-[260px] lg:px-[180px] md:px-[20px] ">
                {t("unleash_the_power")}
              </div>
              <div className="z-[1] xxl:text-[15px] xl:text-[15px] lg:text-[15px] md:text-[15px] sm:text-[15px] text-center xd:text-[15px] font-[500] text-[#FFFFFF] leading-7  h-auto font-manrope md:px-[20px]  sm:px-[20px]  xd:px-[20px] py-[28px] xxl:w-3/4 xl:w-3/4 lg:w-3/4 xxl:px-5 xl:px-5 lg:px-1 ">
                {t("join_the_most")}
              </div>
              <button
                onClick={handleWaitlistOpen}
                id="submit"
                type="submit"
                className=" z-[1] my-[4px] xxl:w-[137px] xl:w-[137px] lg:w-[137px] md:w-full sm:w-full xd:w-full  cursor-pointer border-none blue_green_gradient  bg-gradient-to-r from-[#4F9DFF1A] to-[#67FFD11A] flex justify-center items-center   h-[3.075rem] bg-[#1D1E20]  border-2 text-[1rem]  !text-[rgba(103,255,209,1)]"
              >
                <span className="flex">
                  {t("start_now")}
                  <img src={SubmitArrow} alt="SubmitArrow" className=" pl-2 " />
                </span>
              </button>
            </div>
          </div>
          <WhyJoinKanaLabs />
          <div className="xxl:mx-[302px] xl:mx-[162px] lg:mx-[75px] md:mx-[20px]   sm:mx-[20px]   xd:mx-[20px]  h-full bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] flex flex-row justify-between items-center rounded-[16px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] shadow-custom">
            <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-center xxl:items-end xl:items-end lg:items-end md:items-center sm:items-center xd:items-center w-full h-full bg-blue_star_bg xxl:!bg-right xl:!bg-right lg:!bg-right md:!bg-bottom  sm:!bg-bottom xd:!bg-bottom     mix-blend-lighten !bg-contain bg-no-repeat rounded-[16px]">
              <div className="flex-[0.45] flex flex-col xxl:pl-[56px] xxl:py-[56px] xl:pl-[56px] xl:py-[56px] lg:pl-[56px] lg:py-[56px] md:px-[20px] md:py-[20px] sm:px-[20px] sm:py-[20px] xd:px-[20px] xd:py-[20px]">
                <div className="text-[26px] font-[600] font-urbanist text-[#FFFFFF] leading-none">
                  {t("dont_miss_chance_to_be_a_part")}
                </div>
                <button
                  onClick={handleWaitlistOpen}
                  id="submit"
                  type="submit"
                  className="mt-[24px] xxl:w-[137px] xl:w-[137px] lg:w-[137px] md:w-full sm:w-full xd:w-full   cursor-pointer border-none blue_green_gradient  bg-gradient-to-r from-[#4F9DFF1A] to-[#67FFD11A] flex justify-center items-center   h-[3.375rem] bg-[#1D1E20]  border-2 text-[1rem]  !text-[rgba(103,255,209,1)]"
                >
                  <span className="flex">
                    {t("start_now")}
                    <img
                      src={SubmitArrow}
                      alt="SubmitArrow"
                      className=" pl-2 "
                    />
                  </span>
                </button>
              </div>

              <div className="flex-[0.55]  w-full  flex flex-row justify-center items-end">
                <img src={BlackPlaystation} alt="black playstation" />
              </div>
            </div>
          </div>
          <Rewards />
          <div className="mb-[180px] flex-[1] xxl:mx-[302px] xl:mx-[162px] lg:mx-[75px] md:mx-[20px]   sm:mx-[20px]   xd:mx-[20px]  h-full bg-[rgba(0,0,0,0.50)] flex xxl:flex-row xl:flex-row lg:flex-row  md:flex-col sm:flex-col xd:flex-col justify-between items-start rounded-[16px] xxl:p-[56px] xl:p-[56px] lg:p-[56px] md:p-[32px] sm:p-[32px] xd:p-[32px]">
            <div className="xxl:flex-[0.5] xl:flex-[0.5] lg:flex-[0.5] xxl:w-[456px] xl:w-[456px] lg:w-[456px] md:w-[456px] sm:w-auto xd:w-auto h-[93px] md:flex-[1] sm:flex-[1] xd:flex-[1] text-[26px] font-urbanist xxl:font-[600] font-[600] text-[white]">
              {t("enjoy_perks")}
              <span className="bg-gradient-to-r  from-[#67FFD1] to-[#4F9DFF] bg-clip-text text-transparent pl-1">
                {t("gaming_journey")}
              </span>
            </div>
            <form
              onSubmit={handleSubmit}
              id="form"
              className="w-full xxl:flex-[0.5] xl:flex-[0.5] lg:flex-[0.5] md:flex-[1] sm:flex-[1] xd:flex-[1] gap-[8px] flex flex-col justify-start items-start xxl:pt-[0px] xl:pt-[0px] lg:pt-[0px] md:pt-[32px]  sm:pt-[32px]  xd:pt-[32px]"
            >
              <input
                type="email"
                className="w-full border-[#7D8595] border-[1px] rounded-[4px] bg-[#1D1E20] py-[14px] px-[16px] outline-none text-[white] text-[15px] font-manrope font-[400] hover:bg-[#2C2D30] hover:border-[white] h-[3.375rem]"
                placeholder="Email*"
                required
                name="email"
                autoComplete="off"
              />
              <button
                id="submit"
                type="submit"
                className=" cursor-pointer border-none blue_green_gradient  bg-gradient-to-r from-[#4F9DFF1A] to-[#67FFD11A] flex justify-center items-center   h-[3.375rem] bg-[#1D1E20]  border-2 text-[1rem]  !text-[rgba(103,255,209,1)] pl-5 xxl:!w-[150px] xl:!w-[150px] lg:!w-[150px] md:!w-full sm:!w-full xd:!w-full"
              >
                {t("enquire")}
                <img
                  src={SubmitArrow}
                  alt="SubmitArrow"
                  className=" pl-2 pr-4 "
                />
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </div>
      {isLoaderVisible && <Loader />}
      {isWaitlistOpen && (
        <WaitListSocialLogin
          handleSocialLoginModelClose={handleSocialLoginModelClose}
        />
      )}
      {profileOne && (
        <ProfileSetUpOne
          handleProfileSetupOneModelClose={handleProfileSetupOneModelClose}
        />
      )}
      {profileThree && (
        <ProfileSetUpThree
          handleProfileSetupThreeModelClose={handleProfileSetupThreeModelClose}
        />
      )}
    </>
  );
};

export default GamePlay;
