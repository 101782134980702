import React, { useEffect, useState } from "react";
import Close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import kanaframe from "../assets/icons/kana-frame.svg";
import { api, backendHost, missionEndpoints } from "helper/constants";
import Toaster from "components/Toaster";
import Loader from "./Loader";
interface TwitterpopupInterface {
  handleCloseTwitterPopup(value: any): void;
  userWallet: any;
  onSuccess(): void;
}
const defaultCreds = {
  oauthToken: "",
  oauthVerifier: "",
};

const TwitterPopup = (props: TwitterpopupInterface) => {
  const { t } = useTranslation();
  const { handleCloseTwitterPopup } = props;
  const [twitterConnected, setTwitterConnected] = useState<boolean>(false);
  const [twitterCreds, setTwitterCreds] = useState(defaultCreds);
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleCloseToaster = () => {
    setErrorMessage("login successfully");
  };
  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => setErrorMessage(""), 2000);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (twitterCreds.oauthToken && twitterCreds.oauthVerifier) {
      connectToTwitter();
    }
  }, [twitterCreds.oauthToken, twitterCreds.oauthVerifier]);

  const handleAuthentication = async (e: any) => {
    setTwitterCreds(defaultCreds); // Reset tokens on every auth attempt
    const authlinkEndpoint: URL = new URL(
      missionEndpoints.twitterAuthlink,
      backendHost
    );
    const response = await api.post(authlinkEndpoint.href).catch((err) => {});
    if (response?.status === 200) {
      const oauth_token =
        new URL(response.data.authlink).searchParams.get("oauth_token") || "";
      setTwitterCreds((prevCreds) => ({
        ...prevCreds,
        oauth_token,
      }));
      const twitterPopup = window.open(
        response.data.authlink,
        "twitterPopup",
        "width=500"
      );
      window.onmessage = (ev) => {
        if (ev.data.eventType === "twitter oauth") {
          setTwitterCreds({
            oauthToken: ev.data.oauth_token,
            oauthVerifier: ev.data.oauth_verifier,
          });
          twitterPopup?.close();
        }
      };
    }
  };
  const connectToTwitter = async () => {
    const connectEndpoint: URL = new URL(
      missionEndpoints.twitterConnect,
      backendHost
    );
    const response = await api
      .post(connectEndpoint.href, twitterCreds)
      .catch((err) => {});
    if (response?.status === 200) {
      setTwitterConnected(true);
    }
  };

  const handleFollow = async (e: any) => {
    setIsLoaderVisible(true);
    setErrorMessage("Login Successfully");
    const followEndPoint: URL = new URL(
      missionEndpoints.twitterFollow,
      backendHost
    );
    const response = await api
      .post(followEndPoint.href, {
        userWallet: props.userWallet,
      })
      .catch((err) => {});
    if (response?.status === 200) {
      setIsLoaderVisible(true);
      setErrorMessage("Login Successfully");

      props.onSuccess();
    }
  };

  return (
    <div>
      <div className="flex flex-row justify-start items-start">
        <div className="xxl:!ml-[20rem] xl:!ml-[20rem] lg:!ml-[14rem] container-fluid z-[1]">
          <div className="fixed z-[2]  inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[12px]  flex flex-row   justify-center xxl:items-center xl:items-center lg:items-center md:items-center sm:items-end xd:items-end">
            <div className="h-[23.563rem] flex flex-col rounded-[16px] w-[35rem] border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
              <div className=" bg-[#ffffff0f] rounded-t-[1rem] h-[4.5rem] w-full flex justify-between p-4">
                <div className=" text-[white] font-inter font-[700] texxt-[1.25rem]">
                  {t("follow_us_on_twitter")}
                </div>
                <img
                  src={Close}
                  alt="Close"
                  className=" cursor-pointer"
                  onClick={handleCloseTwitterPopup}
                />
              </div>
              <div className=" flex justify-between p-3 border-b-2 border-[rgba(245,247,250,0.06)] ">
                <span className="text-[#D2D2D2] font-inter font-[500] pt-3">
                  {t("connect_twitter")}
                </span>
                <button
                  onClick={handleAuthentication}
                  className="h-[51px] w-[168px] flex flex-row justify-center items-center cursor-pointer rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow"
                >
                  {t("Connect_twitter_1")}
                </button>
              </div>
              <div className=" flex justify-center items-center py-3">
                <div
                  className={` bg-[#0C0C0D] gap-[8px] w-[528px] h-[96px] rounded-[1rem] flex justify-between p-[2%_5%] ${
                    !twitterConnected && "opacity-20"
                  } `}
                >
                  <div className=" flex">
                    <img src={kanaframe} alt="kana-frame" />
                    <div className=" text-white flex flex-col text-[1rem] font-inter font-[800] px-3 py-2.5">
                      Kanalabs
                      <span className=" text-[#A5A5A6] font-[400] font-manrope pt-1">
                        @kanalabs
                      </span>
                    </div>
                  </div>
                  <button
                    className="h-[51px] w-[97px] my-2 flex flex-row justify-center items-center rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow"
                    onClick={handleFollow}
                    disabled={!twitterConnected}
                  >
                    follow
                  </button>
                </div>
              </div>
              <div className=" flex justify-center py-3">
                <button
                  className="h-[51px] w-[512px]  flex flex-row justify-center items-center cursor-pointer rounded-[16px] backdrop-blur-[54.36563491821289px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow"
                  onClick={handleCloseTwitterPopup}
                >
                  {t("claim_reward")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {errorMessage !== "" && (
        <Toaster
          message={errorMessage}
          handleCloseToaster={handleCloseToaster}
        />
      )}
      {isLoaderVisible && <Loader />}
    </div>
  );
};

export default TwitterPopup;
