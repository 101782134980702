import React, { useEffect, useState } from "react";
import { useStore } from "store";
import { Navigate, useLocation } from "react-router-dom";

const ProtectedRoute = ({ children }: any) => {
  const { web3Instance, profileDetails } = useStore();
  const [isWalletconnected, setIsWalletconnected] = useState<boolean>(false)

  useEffect(() => {
    if (web3Instance.status === 'connected') {
     const isPresent = localStorage.getItem('userDetails')
     if(isPresent !== null){
      setIsWalletconnected(true)
      return
     }
     setIsWalletconnected(false)
    return
    }
    setIsWalletconnected(false)
  }, [web3Instance.status, profileDetails])
  let location = useLocation();
  if (isWalletconnected && location.pathname === '/') {
    return <Navigate to="/game-dashboard" state={{ from: location }} replace />;
  }
  if (!isWalletconnected && location.pathname === '/game-dashboard') {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children
};

export default ProtectedRoute;
