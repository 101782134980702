import React, { useState, useCallback, useEffect } from "react";
import Pagination from "../Pagination";
import { useTranslation } from "react-i18next";
import { api } from "helper/constants";
const LeaderBoard = () => {
  const { t } = useTranslation();
  const [leaderBoard, setLeaderBoard] = useState<any[]>();
  const [splittedLeaderBoard, setSplittedLeaderBoard] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleGetLeaderBoard = useCallback(async () => {
    const leaderBoardResponse: any = await api
      .get("/getLeaderBoard")
      .then((res) => {
        return res;
      })
      .catch((err) => console.log(""));
    setLeaderBoard(leaderBoardResponse.data.userDetails);
    const numberOfPages = leaderBoardResponse?.data?.userDetails?.length / 5;
    setTotalPages(Math.round(numberOfPages));
    const res = [];
    for (
      let i = 0;
      i < leaderBoardResponse?.data?.userDetails?.length;
      i += 5
    ) {
      const chunk = leaderBoardResponse.data.userDetails.slice(i, i + 5);
      res.push(chunk);
    }
    setSplittedLeaderBoard(res);
  }, []);

  useEffect(() => {
    handleGetLeaderBoard();
  }, [handleGetLeaderBoard]);
  return (
    <div className=" xxl:w-1/2  xl:w-1/2 lg:w-1/2 md:w-auto sm:w-auto xd:w-auto  xxl:h-[39.375rem] xl:h-[39.375rem] lg:h-[39.375rem] md:h-[39.375rem] sm:h-[31.188rem] xd:h-[31.188rem] xxl:ml-8 xl:ml-8 lg:ml-2 md:ml-0 sm:ml-0 xd:ml-0 rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] xxl:mt0 xl:mt-0 lg:mt-0 md:mt-8 sm:mt-8 xd:mt-8">
      <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem] pl-6 pt-3 overflow-x-auto">
        {t("leadeboard")}
      </div>
      <div className=" pt-4 pl-4 overflow-auto ">
        <div className=" h-[20.5rem]">
          <ul className=" flex justify-between p-[0%_5%]  xxl:w-auto xl:w-auto lg:w-auto md:w-full sm:w-[20.875rem] xd:w-[20.875rem] border-b-2 border-b-[#364152] text-[#4B5565] font-manrope font-[400] text-[0.875rem]">
            <li className="  py-3">{t("rank")}</li>
            <li className=" py-3 w-[5rem] pl-3">{t("name")}</li>
            <li className=" pl-16 py-3">{t("xp")}</li>
            <li className="  py-3">{t("badges")}</li>
          </ul>
          {leaderBoard &&
            splittedLeaderBoard[currentPage - 1].map(
              (data: any, key: number) => {
                return (
                  <div
                    key={key}
                    className=" flex justify-between overscroll-auto p-[0%_6%] py-3 xxl:w-auto xl:w-auto lg:w-auto md:w-full sm:w-[20.875rem] xd:w-[20.875rem] border-b-2 border-b-[#364152] text-[#FFFFFF] font-manrope font-[400] text-[0.875rem]"
                  >
                    <div className="">{key + currentPage * 5 - 4}</div>
                    <div className="w-[5rem] text-left">{data.userName}</div>
                    <div className="">{data.points}</div>
                    <div className="">{data.claimedBadgeCount}</div>
                  </div>
                );
              }
            )}
        </div>
      </div>
      <div className=" flex justify-center xxl:pt-6 xl:pt-6 lg:pt-6 md:pt-6 sm:pt-3.5 xd:pt-3.5 text-[#697586] font-manrope font-[500] text-[0.875rem]">
        Showing {currentPage} of {totalPages}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default LeaderBoard;
