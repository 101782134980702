import React, { useState } from "react";
import Logo from "../assets/icons/kana_logo.svg";
import menu from "../assets/icons/menu.svg";
import username from "../assets/icons/username.svg";
import Icon from "../assets/icons/Icon.svg";
import HeaderModal from "./HeaderModal";

interface NavbarForPublicProfileInterface {
  profileDetails: any;
}

const NavbarForPublicProfile = (props: NavbarForPublicProfileInterface) => {
  const { profileDetails } = props;
  const [isOpenHeader, setIsOpenHeader] = useState(false);

  const handleHeaderModal = () => {
    setIsOpenHeader((prevstatus) => {
      return !prevstatus;
    });
  };

  return (
    <>
      <div className="!fixed !top-0  z-[2] font-inter w-[100%] h-[6rem] bg-[#1d1e20] backdrop-blur-[3.125rem] px-[40px] ">
        <div className="flex justify-between items-center pt-4 ">
          <img src={Logo} alt="/" />
          <ul className="flex flex-row justify-center items-center gap-[48px] text-[#697586] text-[1rem] font-[700]">
            <li className=" cursor-pointer flex flex-row justify-center items-center xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden">
              <img src={Icon} alt="icon/" />
              <span className=" pl-2 ">{profileDetails?.points} XP</span>
            </li>
            <li className=" cursor-pointer flex flex-row justify-center items-center xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden">
              <img src={username} alt="Username" />
              <span className=" pl-2 ">{profileDetails?.userName}</span>
            </li>
             <li
              className=" cursor-pointer flex-row justify-center items-center"
              onClick={()=>handleHeaderModal()}
            >
              <img src={menu} alt="menu" />
            </li>
          </ul>
        </div>
      </div>
      {isOpenHeader && (
        <HeaderModal handleCloseHeader={handleHeaderModal} />
      )}
    </>
  );
};

export default NavbarForPublicProfile;
