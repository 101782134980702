import React from 'react'
import kana_logo from '../assets/icons/kana_logo.svg';
import Twitter from '../assets/icons/twitter.svg';
import Discord from '../assets/icons/discord.svg';
import Telegram from '../assets/icons/telegram.svg';
import Medium from '../assets/icons/medium.svg';
import Envelope from '../assets/icons/Envelope.svg';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className='!z-[0] bg-footer_gradient !bg-left-bottom 	 bg-no-repeat !bg-contain flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col-reverse	 sm:flex-col-reverse	 xd:flex-col-reverse	 justify-between items-start border-t-[#2C2D30] border-[1px] border-transparent xxl:px-[150px] xl:px-[48px] lg:px-[48px] md:px-[30px] sm:px-[20px] xd:px-[20px] pt-[70px] pb-[37px] ' >
      <div className='flex flex-col xxl:flex-[0.5] xxl:justify-start xxl:items-start xl:flex-[0.5]  xl:justify-start xl:items-start lg:flex-[0.3]  lg:justify-start lg:items-start md:w-full md:flex-[1] md:justify-center md:items-center sm:w-full sm:flex-[1] sm:justify-center sm:items-center xd:w-full xd:flex-[1] xd:justify-center xd:items-center'>
        <div className='text-[#7D8595] text-[12px] font-manrope font-[400] xxl:hidden xl:hidden lg:hidden md:flex  sm:flex  xd:flex pb-[55px]'>{t('terms_condition')}</div>
        <a href='https://kanalabs.io/'><img src={kana_logo} alt='kana_logo' /></a>
        <div className=' text-[12px] font-manrope font-[400] text-[#7D8595] py-[20px]'>{t('copyright')}</div>
      </div>
      <div className='xxl:flex-[0.5] xl:flex-[0.5] lg:flex-[0.7] flex xxl:justify-start xxl:items-start xl:justify-start xl:items-start  xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col h-full w-full md:w-full md:flex-[1] md:justify-center md:items-center sm:w-full sm:flex-[1] sm:justify-center sm:items-center xd:w-full xd:flex-[1] xd:justify-center xd:items-center'>
        <div className='xxl:flex-[0.7] xl:flex-[0.7] lg:flex-[0.7] md:flex-[1]  sm:flex-[1]  xd:flex-[1] flex flex-col justify-between items-start h-[300px] w-full '>
          <div className='flex flex-row w-full h-full justify-start items-start'>
            <div className='flex flex-col flex-[0.5] gap-[12px]'>
              <div className='uppercase text-[#7D8595] text-[14px] font-manrope font-[400] '>{t('company')}</div>
              <div className=' text-[#FFFFFF] text-[16px] font-manrope font-[500] hover:text-[#67FFD1] cursor-pointer'><a className='hover:text-[#67FFD1]' href='/who-we-are'>{t('who_we_are')}</a></div>
              <div className=' text-[#FFFFFF] text-[16px] font-manrope font-[500] hover:text-[#67FFD1] cursor-pointer'><a className='hover:text-[#67FFD1]' href='https://docs.kanalabs.io/faq' target='_blank' rel="noreferrer">{t('faq')}</a></div>
              <div className=' text-[#FFFFFF] text-[16px] font-manrope font-[500] hover:text-[#67FFD1] cursor-pointer'><a className='hover:text-[#67FFD1]' href='https://kanalabs.medium.com' target='_blank' rel="noreferrer">{t('blog')}</a></div>
            </div>
            <div className='flex flex-col flex-[0.5] gap-[12px]'>
              <div className='uppercase text-[#7D8595] text-[14px] font-manrope font-[400] '>Links</div>
              <div className=' text-[#FFFFFF] text-[16px] font-manrope font-[500] hover:text-[#67FFD1] cursor-pointer'><a className='hover:text-[#67FFD1]' href='https://docs.kanalabs.io/' target='_blank' rel="noreferrer">{t('documentation')}</a></div>
              <div className=' text-[#FFFFFF] text-[16px] font-manrope font-[500] hover:text-[#67FFD1] cursor-pointer'><a className='hover:text-[#67FFD1]' href='https://docs.kanalabs.io/smart-wallet-sdk/mirai-sdk-the-omni-chain-smart-wallet' target='_blank' rel="noreferrer">{t('sdk')}</a></div>
              <div className=' text-[#FFFFFF] text-[16px] font-manrope font-[500] hover:text-[#67FFD1] cursor-pointer'><a className='hover:text-[#67FFD1]' href='https://app.kanalabs.io' target='_blank' rel="noreferrer">{t('launch_app')}</a></div>
            </div>
          </div>
          <div className='text-[#7D8595] text-[12px] font-manrope font-[400] xxl:flex xl:flex lg:flex md:hidden  sm:hidden  xd:hidden'>T{t('terms_condition')}</div>
        </div>
        <div className='xxl:flex-[0.3] xl:flex-[0.3] lg:flex-[0.3] md:flex-[1]  sm:flex-[1]  xd:flex-[1] flex flex-col !justify-between h-[300px] !w-full '>
          <div className='flex flex-col gap-[12px] xxl:py-[0px] xl:py-[0px] lg:py-[0px] md:py-[50px]  sm:py-[50px]  xd:py-[50px]'>
            <div className='uppercase text-[#7D8595] text-[14px] font-manrope font-[400] '>{t('follow_us')}</div>
            <div className='flex flex-row  items-center gap-[24px] !flex-[0.5]'>
              <a href='https://t.me/kanalabsofficial' target='_blank' rel="noreferrer">
                <img src={Telegram} alt='Telegram' />
              </a>
              <a href='https://twitter.com/kanalabs' target='_blank' rel="noreferrer">
                <img src={Twitter} alt='Twitter' />
              </a>
              <a href='https://kanalabs.medium.com/' target='_blank' rel="noreferrer">
                <img src={Medium} alt='Medium' />
              </a>
              <a href='https://discord.gg/uKXNhPubvC' target='_blank' rel="noreferrer">
                <img src={Discord} alt='Discord' />
              </a>
            </div>
            <div className=' flex flex-row !justify-between items-center mt-[26px] w-full gap-[32px]'>
              <a href='/contact-us-dev'>
                <div className=' cursor-pointer xxl:flex-[0.5] xl:flex-[0.5] lg:flex-[0.5] md:flex-[0.2] sm:flex-[0.5] xd:flex-[0.5] !z-[0] right_gradient  !text-[#FFFFFF] text-[14px] font-[600] font-manrope py-[10px] xxl:px-[30px] xl:px-[30px] lg:px-[30px] md:px-[36px] sm:px-[10px] xd:px-[10px] text-center w-[168px] h-[39px] flex !flex-row justify-center items-center'>
                  {t('contact')}
                </div>
              </a>
              <a href="mailto:hello@kanalabs.io">
                <div className='flex-[1] text-[#7D8595] hover:text-[#7D8595]  !text-left text-[12px] font-manrope font-[400] flex flex-row gap-[10px] justify-start xxl:hidden xl:hidden lg:hidden md:flex sm:flex xd:flex  cursor-pointer'>
                  <img src={Envelope} alt='Envelope' />{t('hello')}
                </div>
              </a>
            </div>
          </div>
          <a href="mailto:hello@kanalabs.io">
            <div className='cursor-pointer text-[#7D8595] hover:text-[#7D8595]  text-[12px] font-manrope font-[400] flex flex-row gap-[10px] xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden'>
              <img src={Envelope} alt='Envelope' />{t('hello')}
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer