import React from 'react'
import ClickAwayListener from 'react-click-away-listener'
import Logo from "../assets/icons/kana_logo.svg";
import GreenMenu from "../assets/icons/green_mobile_menu.svg";
import WhiteUpArrow from "../assets/icons/whiteUpArrow.svg";
import UpLeftArrow from "../assets/icons/UpLeftArrow.svg";
import { useTranslation } from 'react-i18next';
import Discord from "../assets/icons/discord.svg";
import Medium from "../assets/icons/medium.svg";
import Twitter_Head from "../assets/icons/twitter.svg";
import Telegram from "../assets/icons/telegram.svg";

interface HeaderModalInterface {
  handleCloseHeader(): any;
}

const HeaderModal = (props: HeaderModalInterface) => {
  const { handleCloseHeader } = props
  const { t } = useTranslation();
  return <ClickAwayListener onClickAway={() => handleCloseHeader()}>
    <div
      className=" !fixed w-full !top-0  text-[white] xxl:h-[400px] xl:h-[400px] lg:h-auto md:h-auto sm:h-auto xd:h-auto !z-[3] flex flex-col justify-start items-start py-[30px] xxl:px-[48px] xl:px-[48px] lg:px-[48px] md:px-[48px] sm:px-[20px] xd:px-[20px]  !bg-[#2C2D30] transition-transform duration-300 ease-in "
      id="menu-switch"
    >
      <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-start items-start w-full">
        <div className="flex flex-row xxl:flex-[0.2] xl:flex-[0.2] lg:flex-[0.2] md:flex-[1] sm:flex-[1] xd:flex-[1] justify-between items-center w-full">
          <a href="https://kanalabs.io/">
            <img src={Logo} alt="kana_logo" />
          </a>{" "}
          <img
            src={GreenMenu}
            alt="GreenMenu"
            className=" cursor-pointer xxl:hidden xl:hidden  lg:hidden md:flex sm:flex xd:flex"
            onClick={()=>handleCloseHeader()}
          />
        </div>
        <div className="  xxl:hidden xl:hidden lg:hidden md:inline-flex sm:inline-flex xd:inline-flex w-full pt-16">
          <a
            className="flex justify-between w-full text-[18px]"
            href="https://dev.kanalabs.io/"
          >
            <div className="text-[#ffffff]">{t("for_developers")}</div>
            <div>
              <img src={WhiteUpArrow} alt="UpLeftArrow" />{" "}
            </div>
          </a>
        </div>
        <div className=" flex justify-between xxl:hidden xl:hidden lg:hidden md:inline-flex sm:inline-flex xd:inline-flex w-full pt-8">
          <a
            className="flex justify-between w-full text-[18px]"
            href="https://dev.kanalabs.io/"
          >
            <div className="text-[#ffffff]">{t("for_trader")}</div>
            <div>
              <img src={WhiteUpArrow} alt="UpLeftArrow" />{" "}
            </div>
          </a>
        </div>
        <div className=" flex justify-between xxl:hidden xl:hidden lg:hidden md:inline-flex sm:inline-flex xd:inline-flex w-full pt-8">
          <a
            className="flex justify-between w-full text-[18px]"
            href="https://dev.kanalabs.io/"
          >
            <div className="text-[#67FFD1]">{t("for_gamers")}</div>
            <div>
              <img src={UpLeftArrow} alt="UpLeftArrow" />{" "}
            </div>
          </a>
        </div>
        <div className="xxl:mt-[0px] xl:mt-[0px] lg:mt-[0px] md:mt-[70px] sm:mt-[70px] xd:mt-[70px] xxl:flex-[0.6] xl:flex-[0.6] lg:flex-[0.6] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col xxl:justify-start xxl:items-start xl:justify-start xl:items-start lg:justify-start lg:items-start md:justify-center md:items-center sm:justify-center sm:items-center xd:justify-center xd:items-center   text-[white]  font-manrope font-[500] xxl:gap-[69px] xl:gap-[69px] lg:gap-[40px]">
          <div className="w-full xxl:flex-[0.6] xl:flex-[0.6] lg:flex-[0.9] md:flex-[1] sm:flex-[1] xd:flex-[1] flex flex-row justify-start items-start xxl:gap-[34px] xl:gap-[34px] lg:gap-[34px] md:gap-[28px]">
            <div className="flex w-full flex-col justify-start items-start ">
              <a
                className="cursor-pointer flex xxl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden xd:hidden xxl:text-[22px] xl:text-[22px] lg:text-[18px] md:text-[18px] sm:text-[18px] xd:text-[18px]    gap-[8px]  !flex-row justify-center items-center  hover:text-[#67FFD1] py-2"
                href="https://dev.kanalabs.io/"
              >
                <div className="text-[#ffffff]">
                  {t("for_developers")}
                </div>
                <div>
                  <img src={WhiteUpArrow} alt="UpLeftArrow" />{" "}
                </div>
              </a>
              <div className="xxl:mt-[80px] xl:mt-[80px] lg:mt-[80px] md:mt-[20px] sm:mt-[10px] xd:mt-[10px] py-[6px] uppercase text-[14px] text-[rgba(255,255,255,0.5)] font-manrope font-[400]">
                {t("company")}
              </div>
              <div className="text-[#FFF] xxl:text-[22px] xl:text-[22px] lg:text-[16px] md:text-[16px] sm:text-[16px] xd:text-[16px] font-manrope font-[500] py-[6px] hover:text-[#67FFD1] cursor-pointer">
                <a className="hover:text-[#67FFD1]" href="/who-we-are">
                  {t("who_we_are")}
                </a>
              </div>
              <div className="text-[#FFF] xxl:text-[22px] xl:text-[22px] lg:text-[16px] md:text-[16px] sm:text-[16px] xd:text-[16px] font-manrope font-[500] py-[6px] hover:text-[#67FFD1] cursor-pointer ">
                <a
                  className="hover:text-[#67FFD1]"
                  href="https://docs.kanalabs.io/faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("faq")}
                </a>
              </div>
              <div className="text-[#FFF] xxl:text-[22px] xl:text-[22px] lg:text-[16px] md:text-[16px] sm:text-[16px] xd:text-[16px] font-manrope font-[500] py-[6px] hover:text-[#67FFD1] cursor-pointer">
                <a
                  className="hover:text-[#67FFD1]"
                  href="https://kanalabs.medium.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("blog")}
                </a>
              </div>
            </div>
            <div className="flex w-full flex-col justify-start items-start">
              <a
                className="py-2 cursor-pointer flex xxl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden xd:hidden xxl:text-[22px] xl:text-[22px] lg:text-[18px] md:text-[18px] sm:text-[18px] xd:text-[18px]     gap-[8px] !flex-row justify-center items-center hover:text-[#FFF]"
                href="https://trade.kanalabs.io/"
              >
                <div>{t("for_trader")}</div>
                <div>
                  <img src={WhiteUpArrow} alt="UpLeftArrow" />{" "}
                </div>
              </a>
              <div className="xxl:mt-[80px] xl:mt-[80px] lg:mt-[80px] md:mt-[20px] sm:mt-[10px] xd:mt-[10px] py-[6px] uppercase text-[14px] text-[rgba(255,255,255,0.5)] font-manrope font-[400]">
                {t("links")}
              </div>
              <div className="text-[#FFF] xxl:text-[22px] xl:text-[22px] lg:text-[16px] md:text-[16px] sm:text-[16px] xd:text-[16px] font-manrope font-[500] py-[6px] hover:text-[#67FFD1] cursor-pointer">
                <a
                  className="hover:text-[#67FFD1]"
                  href="https://docs.kanalabs.io/sdk-and-integrations/mirai-sdk"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("sdk")}
                </a>
              </div>
            </div>
          </div>
          <div className="xxl:flex-[0.4] xl:flex-[0.4] lg:flex-[0.1] md:flex-[1] sm:flex-[1] xd:flex-[1] w-full">
            <div className="flex w-full flex-col justify-start items-start">
              <div className="py-2 cursor-pointer flex xxl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden xd:hidden xxl:text-[22px] xl:text-[22px] lg:text-[18px] md:text-[18px] sm:text-[18px] xd:text-[18px]     gap-[8px] !flex-row justify-center items-center text-[#67FFD1] ">
                <div>{t("for_gamers")}</div>
              </div>
            </div>
            <div className="flex flex-col xxl:justify-start xxl:items-start xl:justify-start xl:items-start lg:justify-start lg:items-start md:justify-center md:items-center sm:justify-center sm:items-center xd:justify-center xd:items-center gap-[12px] xxl:py-[0px] xl:py-[0px] lg:py-[0px] md:py-[0px]  sm:py-[0px]  xd:py-[0px]">
              <div className="py-2 text-[transparent]">{t("dd")}</div>
              <div className="xxl:mt-[30px] xl:mt-[30px] lg:mt-[30px] md:mt-[20px] sm:mt-[20px] xd:mt-[20px] uppercase  text-[14px] text-[rgba(255,255,255,0.5)] font-manrope font-[400] ">
                {t("follow_us")}
              </div>
              <div className="flex flex-row  items-center  gap-[24px] !flex-[0.5]">
                <a
                  href="https://t.me/kanalabsofficial"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Telegram} alt="Telegram" />
                </a>
                <a
                  href="https://twitter.com/kanalabs"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Twitter_Head} alt="Twitter" />
                </a>
                <a
                  href="https://kanalabs.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Medium} alt="Medium" />
                </a>
                <a
                  href="https://discord.com/invite/CrqCaQ45kZ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={Discord} alt="Discord" />
                </a>
              </div>
              <div className=" flex flex-row xxl:!justify-start xxl:items-start xl:!justify-start xl:items-start lg:!justify-start lg:items-start md:!justify-center md:items-center sm:!justify-center sm:items-center xd:!justify-center xd:items-center  mt-[26px] w-full gap-[32px]">
                <a href="/contact-us-dev">
                  <div className=" cursor-pointer xxl:flex-[0.5] xl:flex-[0.5] lg:flex-[1] md:flex-[1] sm:flex-[1] xd:flex-[1] !z-[0] right_gradient  !text-[#FFFFFF] text-[14px] font-[600] font-manrope py-[10px] xxl:px-[46px] xl:px-[46px] lg:px-[46px] md:px-[36px] sm:px-[10px] xd:px-[10px] text-center xxl:!w-[168px] xl:!w-[168px] lg:!w-[168px] md:w-full sm:w-full xd:w-full h-[39px] flex !flex-row justify-center items-center">
                    {t("contact")}
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          className="xxl:flex-[0.2] xl:flex-[0.2] lg:flex-[0.2]  xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden  flex flex-row justify-end items-end"
          onClick={()=>handleCloseHeader()}
        >
          <img
            src={GreenMenu}
            alt="GreenMenu"
            className=" cursor-pointer"
          />
        </div>
      </div>
    
    </div>
  </ClickAwayListener>
}

export default HeaderModal