import { ProfileDetails } from 'helper/types';
import { StateSlice, Store } from '../types';

export type AppSlice = {
    web3Instance: any;
    isWalletConnected: boolean;
    sdk: any;
    address: string;
    profileDetails: ProfileDetails,
    activeTab: string;
    badges: any;
    claimedBadges: any;
    updateWeb3Instance(payload: any): void;
    updateIsWalletConnected(payload: boolean): void;
    updateSdk(payload: any): void;
    updateAddress(payload: string): void;
    updateProfileDetails(payload: ProfileDetails): void;
    updateActiveTab(payload: any): void;
    updateBadges(payload: any): void;
    updateClaimedBadges(payload: any) : void;
};


export const createAppSlice: StateSlice<Store, AppSlice> = (set) => ({
    web3Instance: {},
    isWalletConnected: false,
    sdk: {},
    address: "",
    email:"",
    activeTab:"home",
    profileDetails: {
        currentLevel: 0,
        discord: '',
        instagram: '',
        language: 'English',
        lastActive: undefined,
        points: 0,
        preferredGenres: undefined,
        referralLink: '',
        signupTimestamp: '',
        steamIdentifier: undefined,
        telegram: undefined,
        twitter: '',
        userDisplayName: '',
        userWallet: '',
        userName: '',
        uuid: '',
        walletWhitelisted: undefined
    },
    badges: [],
    claimedBadges: [],
    updateWeb3Instance(payload: AppSlice['web3Instance']) {
        set({ web3Instance: payload });
    },
    updateIsWalletConnected(payload: AppSlice['isWalletConnected']) {
        set({ isWalletConnected: payload });
    },
    updateSdk(payload: AppSlice['sdk']) {
        set({ sdk: payload });
    },
    updateAddress(payload: AppSlice['address']) {
        set({ address: payload });
    },
    updateProfileDetails(payload: AppSlice['profileDetails']) {
        set({ profileDetails: payload });
    },
    updateActiveTab(payload: AppSlice['activeTab']) {
        set({ activeTab: payload });
    },
    updateBadges(payload: AppSlice['badges']) {
        set({ badges: payload });
    },
    updateClaimedBadges(payload: AppSlice['claimedBadges']) {
        set({ claimedBadges: payload });
    }
});
