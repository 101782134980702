import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useStore } from "store";
import Toaster from "../../components/Toaster";

interface ProfileSetupThreeInterface {
  handleProfileSetupThreeModelClose(response: boolean): void;
}

const ProfileSetUpThree = (props: ProfileSetupThreeInterface) => {
  const { handleProfileSetupThreeModelClose } = props;
  const { profileDetails, updateProfileDetails } = useStore();
  const [favouriteGenres, updateFavouriteGenres] = useState([
    {
      id: "Action",
      selected: false,
    },
    {
      id: "Adventure",
      selected: false,
    },
    {
      id: "RPG",
      selected: false,
    },
    {
      id: "Strategy",
      selected: false,
    },
    {
      id: "Simulation",
      selected: false,
    },
    {
      id: "Sports and Racing",
      selected: false,
    },
    {
      id: "Horror",
      selected: false,
    },
    {
      id: "Fighting",
      selected: false,
    },
    {
      id: "MMORPG",
      selected: false,
    },
    {
      id: "Puzzle",
      selected: false,
    },
  ]);
  const { t } = useTranslation();
  const [selectedGenres, setSelectedGenres] = useState(
    profileDetails?.preferredGenres
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleUpdateFavouriteGeneres = useCallback(() => {
    const favouriteGenreUpdate: any = [...favouriteGenres];
    if (selectedGenres?.length > 0) {
      favouriteGenreUpdate.forEach((person: any) => {
        let copyFromPerson = selectedGenres.find(
          (ele: any) => ele === person.id
        );
        if (copyFromPerson !== undefined) {
          person.selected = true;
        } else {
          person.selected = false;
        }
      });
      updateFavouriteGenres(favouriteGenreUpdate);
    } else {
      favouriteGenreUpdate.map((element: any) => (element.selected = false));
      updateFavouriteGenres(favouriteGenreUpdate);
    }
  }, [selectedGenres]);

  useEffect(() => {
    handleUpdateFavouriteGeneres();
  }, [handleUpdateFavouriteGeneres, selectedGenres]);

  const handleProfileTwo = () => {
    handleProfileSetupThreeModelClose(false);
  };

  const handleGamePlay = () => {
    if (selectedGenres.length <= 0) {
      setErrorMessage("Please Select any one Genres");
      return;
    }
    handleProfileSetupThreeModelClose(true);
  };

  const handleGenreClick = (selectedGenre: string) => {
    if (selectedGenres?.length > 0) {
      const newGenres = [...selectedGenres];
      const isAlreadypresent = newGenres.find(
        (genres) => genres === selectedGenre
      );
      if (isAlreadypresent === undefined) {
        newGenres.push(selectedGenre);
        setSelectedGenres(newGenres);
        const newprofileDetails = { ...profileDetails };
        newprofileDetails.preferredGenres = newGenres;
        updateProfileDetails(newprofileDetails);
      } else {
        const newSelectedArray = newGenres.filter(
          (value) => isAlreadypresent !== value
        );
        setSelectedGenres(newSelectedArray);
        const newprofileDetails = { ...profileDetails };
        newprofileDetails.preferredGenres = newSelectedArray;
        updateProfileDetails(newprofileDetails);
      }
    } else {
      const newGenres = [];
      newGenres.push(selectedGenre);
      setSelectedGenres(newGenres);
      const newprofileDetails = { ...profileDetails };
      newprofileDetails.preferredGenres = newGenres;
      updateProfileDetails(newprofileDetails);
    }
  };

  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => setErrorMessage(""), 2000);
    }
  }, [errorMessage]);

  return (
    <>
      <div className="fixed z-[2]  inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[54px]  flex flex-row   justify-center xxl:items-center xl:items-center lg:items-center md:items-center sm:items-end xd:items-end">
        <div className="h-auto   p-[16px] flex flex-col rounded-[16px] w-[536px] border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
          <div className="mb-[35px] xxl:flex xl:flex lg:flex md:flex sm:hidden xd:hidden flex-row justify-between items-center text-[white] text-[24px] font-inter font-[700]">
            <div>{t("profile_setup")}</div>
          </div>
          <div className="xxl:hidden xl:hidden lg:hidden md:hidden sm:flex xd:flex flex-col justify-center items-center ">
            <div className="text-[white] text-[24px] font-inter font-[700] mb-10">
              {t("profile_setup")}
            </div>
          </div>
          <div className="xxl:px-[24px] xl:px-[24px] lg:px-[24px]  md:px-[0px] sm:px-[0px] xd:px-[0px]  flex flex-col font-[manrope] text-[#7D8595] text-[15px] font-[400] gap-[8px] w-full">
            <div>{t("favourite_genres")}</div>
            <div className="flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-start items-start gap-[8px]">
              <div className="flex flex-row justify-start gap-[8px] items-start flex-wrap">
                {favouriteGenres?.map((genres: any, key: number) => {
                  return (
                    <div
                      key={key}
                      className={`cursor-pointer text-[white] text-[15px] font-manrope font-[400] p-[8px] xxl:px-[8px]  xl:px-[8px] lg:px-[8px] md:px-[8px] sm:px-[28px] xd:px-[18px] rounded-[8px]
                  ${genres.selected === true ? "bg-[#4A4B4D]" : "bg-[#111213]"}
                  `}
                      onClick={() => {
                        handleGenreClick(genres.id);
                      }}
                    >
                      {genres.id}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center gap-[8px] ">
            <div
              className="h-[51px] flex flex-row justify-center items-center cursor-pointer px-[16px] rounded-[16px] backdrop-blur-[54.36563491821289px] mt-[24px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow w-full"
              onClick={handleProfileTwo}
            >
              <div className="w-full text-center">{t("previous")}</div>
            </div>
            <div
              className="h-[51px] flex flex-row justify-center items-center cursor-pointer px-[16px] rounded-[16px] backdrop-blur-[54.36563491821289px] mt-[24px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow w-full"
              onClick={handleGamePlay}
            >
              <div className="w-full text-center">{t("save")}</div>
            </div>
          </div>
        </div>
        {errorMessage !== "" && (
          <Toaster
            message={errorMessage}
            handleCloseToaster={handleCloseToaster}
          />
        )}
      </div>
    </>
  );
};

export default ProfileSetUpThree;
