import React, { useEffect } from "react";
import Close from "../assets/icons/close.svg";

interface ToasterInterface {
  message: any;
  handleCloseToaster(): void;
}

const Toaster = (props: ToasterInterface) => {
  const { message, handleCloseToaster } = props;
  
  useEffect(()=>{
    if (message !== "") {
      setTimeout(() => handleCloseToaster(), 2000);
    }
  },[handleCloseToaster, message])

  return (
    <div>
      <div className="fixed top-4 right-4 toaster_message rounded-[1rem] text-white p-4 shadow w-[20rem] h-auto z-[100]">
        <div className="flex items-center justify-between">
          <div className="flex-1 pr-8">
            <p className=" text-[0.875rem] text-[#00FFFF]">{message}</p>
          </div>
          <div className="cursor-pointer" onClick={() => handleCloseToaster()}>
            <img src={Close} alt="close" className=" w-4 h-4" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toaster;
