import React, { useCallback, useEffect, useState } from "react";
import Facebook from "../assets/icons/facebook__waitlist.svg";
import Twitter from "../assets/icons/twitter_x_logo.svg";
import Discord from "../assets/icons/Discord-color.svg";
import Google from "../assets/icons/google__waitlist.svg";
import Close from "../assets/icons/close.svg";
import { useTranslation } from "react-i18next";
import { useStore } from "store";
import { WALLET_ADAPTERS, WALLET_ADAPTER_TYPE } from "@web3auth/base";
import Toaster from "../components/Toaster";
import Loader from "./Loader";
import loginwait from "../assets/icons/loginwait-min.png";
import { handleInitWallet } from "helper/reusableMethods";

type LOGIN_PROVIDER_TYPE =
  | "google"
  | "facebook"
  | "apple"
  | "discord"
  | "twitch"
  | "github"
  | "twitter"
  | "email_passwordless"
  | "email";

interface WaitListSocialLoginInterface {
  handleSocialLoginModelClose(status: boolean, address: string): void;
}
const WaitListSocialLogin = (props: WaitListSocialLoginInterface) => {
  const { handleSocialLoginModelClose } = props;
  const { web3Instance, updateAddress, updateSdk } = useStore();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { t } = useTranslation();
  const [isLoaderVisible, setIsLoaderVisible] = useState<boolean>(false);

  const loginWithAdapter = useCallback(
    async (
      adapter: WALLET_ADAPTER_TYPE,
      loginProvider?: LOGIN_PROVIDER_TYPE,
      login_hint?: string
    ) => {
      if (!web3Instance) {
        setIsLoaderVisible(false);
        localStorage.clear();
        setErrorMessage("something_went_wrong_please_try_again");
        return false;
      }

      let web3Provider;
      try {
        web3Provider = await web3Instance.connectTo(adapter, {
          loginProvider,
          login_hint,
        });
        if (!web3Provider) {
          setIsLoaderVisible(false);
          localStorage.clear();
          setErrorMessage("failed_to_get_connection_provider");
          return false;
        }
        return true;
      } catch (e) {
        setIsLoaderVisible(false);
        localStorage.clear();
        setErrorMessage(
          `Failed to login! Reason: ${
            e instanceof Error && e?.message ? e.message : "unknown"
          }.`
        );
        return false;
      }
    },
    [web3Instance]
  );

  const handleConnectWallet = async (typeOfLogin: LOGIN_PROVIDER_TYPE) => {
    if (web3Instance?.status === "connected") {
      web3Instance.logout();
    }

    // handleSocialLoginModelClose(true)
    // return
    setIsLoaderVisible(true);
    const response = await loginWithOpenLogin(typeOfLogin);
    if (response) {
      const { status, sdk, address } = await handleInitWallet(web3Instance);
      if (status) {
        web3Instance.logout();
        setIsLoaderVisible(false);
        return;
      }
      updateAddress(address);
      updateSdk(sdk);
      setIsLoaderVisible(false);
      handleSocialLoginModelClose(false, address);
    }
  };

  const loginWithOpenLogin = useCallback(
    async (loginProvider: LOGIN_PROVIDER_TYPE, login_hint?: string) =>
      loginWithAdapter(WALLET_ADAPTERS.OPENLOGIN, loginProvider, login_hint),
    [loginWithAdapter]
  );

  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => setErrorMessage(""), 2000);
    }
  }, [errorMessage]);

  return (
    <>
      <div className="fixed z-[2]  inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[54px]  flex flex-row   justify-center items-center xxl:px-[20%] xl:px-[15%] lg:px-[5%] md:pt-[20px]  sm:pt-[20px]  xd:pt-[20px] ">
        <div className="gap-[16px] p-[16px] flex xxl:flex-col xl:flex-col lg:flex-col md:flex-col sm:flex-col xd:flex-col rounded-[16px] !w-auto h-auto border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
          <div className="xxl:hidden xl:hidden lg:hidden md:flex sm:flex xd:flex flex-col justify-center items-center ">
            <div className="text-[white] text-[24px] font-inter font-[700]">
              {t("sign_in")}
            </div>
          </div>
          <div className="xxl:flex xl:flex lg:flex md:hidden sm:hidden xd:hidden flex flex-row justify-between items-center text-[white] text-[24px] font-inter font-[700]">
            <div>{t("sign_in")}</div>
            <div
              onClick={() => handleSocialLoginModelClose(true, "")}
              className="cursor-pointer"
            >
              <img src={Close} alt="Close" />
            </div>
          </div>
          <div className=" mix-blend-lighten  bg-bottom flex-[0.5] xxl:flex xl:flex lg:flex md:flex sm:flex xd:flex flex-col justify-end items-center w-full h-full rounded-[16px] border-[1px] border-[rgba(255,255,255,0.10)]">
            <div>
              <img src={loginwait} alt="loginwait" />
            </div>
          </div>
          <div className="flex-[0.5] h-[100%] ">
            <div className="xxl:!h-[338px] xl:!h-[338px] lg:!h-[338px] md:!h-auto sm:!h-auto xd:!h-auto text-[16px] font-[700] font-inter text-[#FFF]   h-auto rounded-[16px] border-[1px] border-[rgba(255,255,255,0.10)]  flex flex-col  items-start">
              <div className=" hover:text-[#FFFFFF] xxl:px-[32px]  xl:px-[32px]  lg:px-[32px] md:px-[24px] sm:px-[18px] xd:px-[18px] xxl:py-[30px] xl:py-[30px] lg:py-[30px] md:py-[24px] sm:py-[18px] xd:py-[18px] hover:bg-[rgba(0,0,0,0.40)] w-full hover:rounded-[16px]">
                <div
                  className="cursor-pointer flex flex-row items-center justify-start"
                  onClick={() => handleConnectWallet("google")}
                >
                  <img src={Google} alt="Google" />
                  <span className="ml-[16px]">{t("google")}</span>
                </div>
              </div>

              <div className=" hover:text-[#FFFFFF] xxl:px-[32px]  xl:px-[32px]  lg:px-[32px] md:px-[24px] sm:px-[18px] xd:px-[18px] xxl:py-[30px] xl:py-[30px] lg:py-[30px] md:py-[24px] sm:py-[18px] xd:py-[18px] hover:bg-[rgba(0,0,0,0.40)] w-full hover:rounded-[16px]">
                <div
                  className="cursor-pointer flex flex-row items-center justify-start"
                  onClick={() => handleConnectWallet("facebook")}
                >
                  <img src={Facebook} alt="Facebook" />
                  <span className="ml-[16px] ">{t("facebook")}</span>
                </div>
              </div>
              <div className=" hover:text-[#FFFFFF] xxl:px-[32px]  xl:px-[32px]  lg:px-[32px] md:px-[24px] sm:px-[18px] xd:px-[18px] xxl:py-[30px] xl:py-[30px] lg:py-[30px] md:py-[24px] sm:py-[18px] xd:py-[18px] hover:bg-[rgba(0,0,0,0.40)] w-full hover:rounded-[16px]">
                <div
                  className="cursor-pointer flex flex-row items-center justify-start"
                  onClick={() => handleConnectWallet("twitter")}
                >
                  <img src={Twitter} alt="Twitter" />
                  <span className="ml-[16px] ">{t("twitter")}</span>
                </div>
              </div>
              <div className=" hover:text-[#FFFFFF] xxl:px-[32px]  xl:px-[32px]  lg:px-[32px] md:px-[24px] sm:px-[18px] xd:px-[18px] xxl:py-[30px] xl:py-[30px] lg:py-[30px] md:py-[24px] sm:py-[18px] xd:py-[18px] hover:bg-[rgba(0,0,0,0.40)] w-full hover:rounded-[16px]">
                <div
                  className="cursor-pointer flex flex-row items-center justify-start"
                  onClick={() => handleConnectWallet("discord")}
                >
                  <img src={Discord} alt="Mail" />
                  <span className="ml-[16px] ">{t("Discord")}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errorMessage !== "" && (
          <Toaster
            message={errorMessage}
            handleCloseToaster={handleCloseToaster}
          />
        )}
        {isLoaderVisible && <Loader />}
      </div>
    </>
  );
};

export default WaitListSocialLogin;
