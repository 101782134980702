import React from "react";
import lockpolygon from "../../assets/icons/lockpolygon.svg";
import { useTranslation } from "react-i18next";

const MissionItem = (props: any) => {
  const { t } = useTranslation();

  return (
    <div className="cursor-pointer w-11/12 h-[5rem] border-[#292B2F] border-[0.063rem] rounded-[1rem] mt-3 flex justify-between p-[0%_4%] ongoing_container ">
      <div onClick={props.onClick} rel="noreferrer">
        <div className=" flex ">
          <img
            src={lockpolygon}
            alt="hexaicon"
            className=" w-[3rem] h-[3rem] mt-3"
          />
          <div className=" text-[#FFFFFF] font-manrope font-[500] text-[1.125rem] xxl:w-full xl:w-full lg:w-full md:w-full sm:w-full xd:w-[8.6rem] pt-4 pl-2">
            {t(props.translationKey)}
          </div>
        </div>
      </div>
      {!!props.points && (
        <div className=" text-[#697586] font-manrope font-[500] text-[1.125rem] pt-4">
          {props.points} xp
        </div>
      )}
    </div>
  );
};

export default MissionItem;
