import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Discord1 from "../assets/icons/Discord1.svg";
import Telegram2 from "../assets/icons/Telegram2.svg";
import Twitter3 from "../assets/icons/Twitter3.svg";
import Linkedn from "../assets/icons/Linkedn.svg";
import Footer from "./Footer";
import { useState } from "react";
import Logo from "../assets/icons/kana_logo.svg";
import Mobile_menu from "../assets/icons/menu.svg";
import Ellipsecontactus from "../assets/icons/Ellipsecontactus.svg";
import SubmitArrow from "../assets/icons/SubmitArrow.svg";
import Tinput from "../assets/icons/Tinput.svg";
import emailjs from "@emailjs/browser";
import HeaderModal from "components/HeaderModal";

const ContactUStraders = () => {
  const form: any = useRef();
  const { t } = useTranslation();
  const [isOpenHeader, setIsOpenHeader] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_9a13dvl",
        "template_a276bjf",
        form.current,
        "fY1zSjfVMNhuXlv6_"
      )
      .then(
        (result: any) => {
          alert("Thankyou, your form submitted successfully");
          window.location.reload();
        },
        (error: any) => {
          alert("Oops, your form submission failed!");
          window.location.reload();
        }
      );
  };

  const handleHeaderModal = () => {
    setIsOpenHeader(!isOpenHeader);
  };

  return (
    <div>
      <div className="!fixed w-full top-0 text-[white] h-[96px] !z-[2] flex flex-row justify-between items-center py-[30px] xxl:px-[48px] xl:px-[48px] lg:px-[48px] md:px-[48px] sm:px-[20px] xd:px-[20px] !bg-[rgba(29,30,32,0.10)] backdrop-blur-[50px] ">
        <div className="xxl:flex-[0.2] xl:flex-[0.2]  lg:flex-[0.2] md:flex-[0.2] sm:flex-[0.9] xd:flex-[0.9]">
          <a href="https://kanalabs.io/">
            <img src={Logo} alt="kana_logo" />
          </a>{" "}
        </div>
        <div className="flex-[0.6] xxl:flex xl:flex lg:flex md:flex  sm:hidden xd:hidden flex-row justify-center items-center text-[white] text-[16px] font-manrope font-[600] gap-[34px]">
          <div className="cursor-pointer">
            <a
              className=" hover:text-[#67FFD1]"
              href="https://trade.kanalabs.io/"
            >
              {t("traders")}
            </a>
          </div>
          <div className="cursor-pointer ">
            <a
              className=" hover:text-[#67FFD1]"
              href="https://dev.kanalabs.io/"
            >
              {t("developers")}
            </a>
          </div>
        </div>
        <div
          className="flex-[0.2] flex flex-row justify-end items-end"
          onClick={() => handleHeaderModal()}
        >
          <img
            src={Mobile_menu}
            alt="Mobile_menu"
            className=" cursor-pointer"
          />
        </div>
      </div>
      {isOpenHeader && <HeaderModal handleCloseHeader={handleHeaderModal} />}
      <div onClick={() => handleHeaderModal()}>
        <div className=" 2xl:flex 2xl:flex-row xl:flex xl:flex-row lg:flex lg:flex-row md:flex md:flex-col sm:flex sm:flex-col max-sm:flex max-sm:flex-col 2xl:justify-around xl:justify-around lg:justify-evenly md:justify-center sm:justify-center max-sm:justify-center items-start no-scrollbar font-manrope">
          <div className=" font-manrope 2xl:pt-28 xl:pt-28 lg:pt-28 md:pt-28 sm:pt-8 max-sm:pt-8 flex flex-col justify-center">
            <div>
              <div className="text-[#7D8595] text-[0.875rem] font-bold uppercase">
                {t("We_are_here_for_you")}
              </div>
              <div className="text-[1.125rem] 2xl:w-[34.625rem] xl:w-[34.625rem] lg:w-[34.625rem] md:w-[34.625rem] sm:w-[21.86rem] max-sm:w-[21.86rem] 2xl: xl:h-[5.25rem] lg:h-[5.25rem] md:h-[5.25rem] sm:h-[10.5rem] max-sm:h-[10.25rem] text-[#FFFFFF] font-normal leading-[1.75rem] mt-3 border-gradient border-l-2 pl-4">
                {t("contact_below_with")}
              </div>
            </div>
            <div className=" 2xl:inline xl:inline lg:inline md:inline sm:hidden max-sm:hidden">
              <div className="text-white text-[1.625rem] pt-20 font-bold ">
                {t("connect_with_the_community")}
              </div>
            </div>
            <div className=" 2xl:hidden xl:hidden lg:hidden md:inline sm:inline max-sm:inline pt-14 flex flex-row justify-center items-center">
              <div className="2xl:w-[27.125rem] xl:w-[27.125rem] lg:w-[24.125rem] md:w-[27.125rem] sm:w-[21.75rem] max-sm:w-[21.75rem] h-[42.375rem] backdrop-filter border-2 border-[#33383c] rounded-[1rem]  pt-6 input_gradient">
                <div className="flex justify-center pt-6">
                  <input
                    className="2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.125rem] h-[3.375rem] bg-[#1D1E20] rounded-[0.23rem] border-[#33383c] border-2 text-[1rem] text-[#7D8595] pl-5 outline-none"
                    placeholder="First Name*"
                  />
                </div>
                <div className="flex justify-center pt-4">
                  <input
                    className="2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[3.375rem] bg-[#1D1E20] rounded-[0.23rem] border-[#33383c] border-2 text-[1rem] text-[#7D8595] pl-5 outline-none"
                    placeholder="Last Name*"
                  />
                </div>

                <div className="flex justify-center pt-4">
                  <input
                    className="2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[3.375rem] bg-[#1D1E20] rounded-[0.23rem] border-[#33383c] border-2 text-[1rem] text-[#7D8595] pl-5 outline-none"
                    placeholder="Email*"
                  />
                </div>
                <div className="flex justify-center pt-5">
                  <input
                    className="2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[8.75rem] bg-[#1D1E20] rounded-[0.23rem] border-[#33383c] border-2 text-[1rem] text-[#7D8595] pl-5 flex items-start justify-start pb-20 outline-none"
                    placeholder="Message*"
                  />
                  <img
                    src={Tinput}
                    alt="Tinput"
                    className="absolute md:top-[31rem] sm:top-[31rem] max-sm:top-[31rem] md:left-[22.7rem] sm:left-[22.7rem] max-sm:left-[18.6rem] cursor-pointer"
                  />
                </div>
                <div className="flex justify-center pt-10">
                  <button className="Button_gradient flex justify-center items-center border-gradient1 2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[3.375rem] bg-[#1D1E20]  border-2 text-[1rem]  text-[#7D8595] pl-5 outline-none button_gradient">
                    {t("submit")}
                    <img
                      src={SubmitArrow}
                      alt="SubmitArrow"
                      className=" pl-2"
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className=" flex flex-row justify-between">
              <div className="2xl:w-[12.5rem] xl:w-[15rem] lg:w-[15rem] md:w-[15rem] sm:w-[9.375rem] max-sm:w-[9.375rem] ">
                <div className="pt-5">
                  <img src={Discord1} alt="Discord1" />
                </div>
                <div className="text-[#7D8595] text-[0.938rem] font-normal pt-1 ">
                  {t("A_friendly_space_to_chat")}
                </div>
                <a
                  href="https://discord.com/invite/CrqCaQ45kZ"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="xxl:mt-10 xl:mt-10 lg:mt-10 md:mt-10 sm:mt-5 max-sm:mt-5 !w-[170px] arrow-animation    cursor-pointer   hover:!text-[#FFFFFF]  !flex-[1]  !text-[#67FFD1] text-[14px] font-[400] font-manrope   h-[46px] flex !flex-row justify-start items-center ">
                    {t("join_discord")}
                  </span>
                </a>
              </div>
              <div className="2xl:w-[12.5rem] xl:w-[15rem] lg:w-[15rem] md:w-[15rem] sm:w-[9.375rem] max-sm:w-[9.375rem] ">
                <div className="pt-5">
                  <img src={Telegram2} alt="Telegram2" />
                </div>
                <div className="text-[#7D8595] text-[0.938rem] font-normal pt-1 ">
                  {t("Stay_up_to_date")}
                </div>
                <div className="flex">
                  <a
                    href="https://t.me/kanalabsofficial"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="xxl:mt-10 xl:mt-10 lg:mt-10 md:mt-10 sm:mt-5 max-sm:mt-5 cursor-pointer !w-[170px] arrow-animation   hover:!text-[#FFFFFF]  !flex-[1]  !text-[#67FFD1] text-[14px] font-[400] font-manrope   h-[46px] flex !flex-row justify-start items-center ">
                      {t("join_telegram")}
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className=" flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row-reverse max-sm:flex-row-reverse justify-between pt-4">
              <div className="2xl:w-[12.5rem] xl:w-[15rem] lg:w-[15rem] md:w-[15rem] sm:w-[9.375rem] max-sm:w-[9.375rem] ">
                <div className="pt-5">
                  <img src={Twitter3} alt="Twitter3" />
                </div>
                <div className="text-[#7D8595] text-[0.938rem] font-normal pt-1 ">
                  {t("read_the_daily")}
                </div>
                <div className="flex">
                  <a
                    href="https://twitter.com/kanalabs"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="xxl:mt-10 xl:mt-16 lg:mt-16 md:mt-10 sm:mt-5 max-sm:mt-5 cursor-pointer !w-[190px] arrow-animation  hover:!text-[#FFFFFF] !flex-[1]  !text-[#67FFD1] text-[14px] font-[400] font-manrope   h-[46px] flex !flex-row justify-start items-center ">
                      {t("follow_on_twitter")}
                    </span>
                  </a>
                </div>
              </div>
              <div className="2xl:w-[12.5rem] xl:w-[15rem] lg:w-[15rem] md:w-[15rem] sm:w-[9.375rem] max-sm:w-[9.375rem] ">
                <div className="pt-5">
                  <img src={Linkedn} alt="Discord1" />
                </div>
                <div className="text-[#7D8595] text-[0.938rem] font-normal pt-1 ">
                  {t("join_our_business")}
                </div>
                <a
                  href="https://www.linkedin.com/company/kanalabs/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span className="xxl:mt-10 xl:mt-10 lg:mt-10 md:mt-10 sm:mt-5 max-sm:mt-5 cursor-pointer !w-[200px] arrow-animation  hover:!text-[#FFFFFF]  !flex-[1]  !text-[#67FFD1] text-[14px] font-[400] font-manrope   h-[46px] flex !flex-row justify-start items-center ">
                    {t("connect_on_Linkedln")}
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div>
            <div className="2xl:pt-28 xl:pt-28 lg:pt-32 md:pt-32 sm:pt-2 max-sm:pt-2 relative  ">
              <div className="relative 2xl:inline xl:inline lg:inline md:hidden sm:hidden max-sm:hidden ">
                <img
                  src={Ellipsecontactus}
                  alt="Ellipsecontactus"
                  className="absolute"
                />
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="2xl:w-[27.125rem] xl:w-[27.125rem] lg:w-[24.125rem] md:w-[27.125rem] sm:w-[21.75rem] max-sm:w-[21.75rem] h-[42.375rem] backdrop-filter border-2 border-[#33383c] rounded-[1rem] backdrop-blur-sm pt-6 input_gradient"
                >
                  <div className="flex justify-center pt-8">
                    <input
                      className=" 2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.125rem] h-[3.375rem] text-[1rem] outline-none text-[white] hover:bg-[#2C2D30] hover:border-[white]  border-[#7D8595] border-[1px] rounded-[4px] bg-[#1D1E20]  pl-5"
                      placeholder="First Name*"
                      required
                      name="first_name"
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex justify-center pt-4">
                    <input
                      className="2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[3.375rem] text-[1rem] outline-none text-[white] hover:bg-[#2C2D30] hover:border-[white]  border-[#7D8595] border-[1px] rounded-[4px] bg-[#1D1E20]  pl-5"
                      placeholder="Last Name*"
                      required
                      name="last_name"
                      autoComplete="off"
                    />
                  </div>

                  <div className="flex justify-center pt-4">
                    <input
                      className="2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[3.375rem] text-[1rem] outline-none text-[white] hover:bg-[#2C2D30] hover:border-[white]  border-[#7D8595] border-[1px] rounded-[4px] bg-[#1D1E20]  pl-5"
                      placeholder="Email*"
                      required
                      name="email"
                      autoComplete="off"
                    />
                  </div>
                  <div className="flex justify-center pt-5">
                    <input
                      className="2xl:w-[22.125rem] relative xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[8.75rem]  text-[1rem] outline-none text-[white] hover:bg-[#2C2D30] hover:border-[white]  border-[#7D8595] border-[1px] rounded-[4px] bg-[#1D1E20]  pl-5 flex items-start justify-start pb-20"
                      placeholder="Message*"
                      required
                      name="message"
                      autoComplete="off"
                    />
                    <img
                      src={Tinput}
                      alt="Tinput"
                      className="absolute 2xl:top-[31.5rem] xl:top-[31.5rem] lg:top-[31.5rem] 2xl:left-[22.7rem] xl:left-[22.7rem] lg:left-[19.1rem] cursor-pointer"
                    />
                  </div>

                  <div className="flex justify-center pt-10">
                    <button
                      type="submit"
                      className="cursor-pointer border-none blue_green_gradient  bg-gradient-to-r from-[#4F9DFF1A] to-[#67FFD11A] flex justify-center items-center 2xl:w-[22.125rem] xl:w-[22.125rem] lg:w-[18.125rem] md:w-[22.125rem] sm:w-[18.75rem] max-sm:w-[18.75rem] h-[3.375rem] bg-[#1D1E20]  border-2 text-[1rem]  !text-[rgba(103,255,209,1)] pl-5"
                    >
                      {t("submit")}
                      <img
                        src={SubmitArrow}
                        alt="SubmitArrow"
                        className=" pl-2 "
                      />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className=" pt-28">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default ContactUStraders;
