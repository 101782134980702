import { CHAIN_NAMESPACES } from "@web3auth/base";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { EthereumPrivateKeyProvider } from "@web3auth/ethereum-provider";
import { ADAPTER_EVENTS } from '@web3auth/base';

export const WebAuthInit = async () => {
  try {
    const clientId: any = "BHu-fep3dHRLAMMFZNSd7Yudq8imJ_lqtmfRtqUvBJnsk1vj1VSBe5fZFmvmw_YJonvYydTO30mxhC4GWBPnDIk";
    const chainConfig = {
      chainNamespace: CHAIN_NAMESPACES.EIP155,
      chainId: "0x1",
      rpcTarget: "https://rpc.ankr.com/eth",
      displayName: "Ethereum Mainnet",
      blockExplorer: "https://goerli.etherscan.io",
      ticker: "ETH",
      tickerName: "Ethereum",
    };
    const web3AuthInstance = new Web3AuthNoModal({
      clientId,
      chainConfig,
      web3AuthNetwork: "mainnet",
    });

    const privateKeyProvider = new EthereumPrivateKeyProvider({
      config: { chainConfig },
    });

    const openloginAdapter: any = new OpenloginAdapter({
      adapterSettings: {
        whiteLabel: {
          name: "W3A Heroes",
          url: "https://web3auth.io",
          logoLight: "https://web3auth.io/images/w3a-L-Favicon-1.svg",
          logoDark: "https://web3auth.io/images/w3a-D-Favicon-1.svg",
          defaultLanguage: "en", // en, de, ja, ko, zh, es, fr, pt, nl
          dark: false, // whether to enable dark mode. defaultValue: false
          theme: {
            primary: "#00D1B2",
          },
        },
        mfaSettings: {
          deviceShareFactor: {
            enable: true,
            priority: 1,
            mandatory: true,
          },
          backUpShareFactor: {
            enable: true,
            priority: 2,
            mandatory: false,
          },
          socialBackupFactor: {
            enable: true,
            priority: 3,
            mandatory: false,
          },
          passwordFactor: {
            enable: true,
            priority: 4,
            mandatory: false,
          },
        },
      },
      loginSettings: {
        mfaLevel: "none",
      },
      privateKeyProvider,
    });
    web3AuthInstance.configureAdapter(openloginAdapter);
    web3AuthInstance.on(ADAPTER_EVENTS.CONNECTED, () => {
      if (!web3AuthInstance?.provider)
        return { error: true, web3Instance: null };
    });

    web3AuthInstance.on(ADAPTER_EVENTS.ERRORED, () => {
      // setIsSigningIn(false);
    });
    await web3AuthInstance.init();
    return { error: false, web3Instance: web3AuthInstance };
  } catch (e) {
    return { error: true, web3Instance: null };
  }
};
