import React, { useEffect, useCallback, useState } from "react";
import {
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import whatsapp from "../assets/icons/whatsapp.svg";
import telegramcolor from "../assets/icons/telegramcolor.svg";
import { useStore } from "store";
import xtwitter from "../assets/icons/Xtwitter.svg";

const ShareSocialLink = () => {
  const { profileDetails } = useStore();
  const [shortendUrl, setShortendUrl] = useState<string>("");

  const shortenerUrl = useCallback(async () => {
    if (profileDetails) {
      let link = `https://play.kanalabs.io/?address=${profileDetails?.userWallet}`;
      setShortendUrl(link);
    }
  }, [profileDetails]);

  useEffect(() => {
    shortenerUrl();
  }, [profileDetails, shortenerUrl]);

  return (
    <>
      <div className="flex xxl:justify-around xl:justify-around lg:justify-around md:justify-around sm:justify-center xd:justify-center xxl:pt-0 xl:pt-0 lg:pt-2 md:pt-0 sm:pt-4 xd:pt-4">
        <TwitterShareButton url={shortendUrl}>
          <div className="mr-3 cursor-pointer">
            <img src={xtwitter} alt="xtwitter" />
          </div>
        </TwitterShareButton>
        <TelegramShareButton url={shortendUrl}>
          <img
            src={telegramcolor}
            alt="telegramcolor"
            className=" mr-3 cursor-pointer"
          />
        </TelegramShareButton>
        <WhatsappShareButton url={shortendUrl}>
          <img src={whatsapp} alt="whatsapp" className=" mr-2 cursor-pointer" />
        </WhatsappShareButton>
      </div>
    </>
  );
};

export default ShareSocialLink;
