import axios from "axios";

export const api = axios.create({
    // baseURL: "http://localhost:8000",
    baseURL: "https://games-dev.kanalabs.io",
    withCredentials: true,
  });

export const defaultProfileDetails = {
  currentLevel: 0,
  discord: '',
  instagram: '',
  language: '',
  lastActive: undefined,
  points: 0,
  preferredGenres: undefined,
  referralLink: '',
  signupTimestamp: '',
  steamIdentifier: undefined,
  telegram: undefined,
  twitter: '',
  userDisplayName: '',
  userWallet: '',
  userName: '',
  uuid: '',
  walletWhitelisted: undefined
}

export const backendHost = "https://games-dev.kanalabs.io";

export const missionEndpoints = {
  steam: "/steam/connect",
  twitterAuthlink: "/twitter/authlink",
  twitterConnect: "/twitter/connect",
  twitterFollow: "/twitter/follow"
}