import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "store";
import Toaster from "../../components/Toaster";
import Vectordown from "../../assets/icons/Vectordown.svg";
import ClickAwayListener from "react-click-away-listener";
import { api } from "helper/constants";

interface ProfileSetupOneInterface {
  handleProfileSetupOneModelClose(): void;
}

const languages = ["English", "Korean", "Spanish", "Japanese"];

const ProfileSetUpOne = (props: ProfileSetupOneInterface) => {
  const { handleProfileSetupOneModelClose } = props;
  const { profileDetails, updateProfileDetails, sdk, updateAddress, address } =
    useStore();
  const { t } = useTranslation();
  const [profileDetailsAtSetupOne, setProfileDetailsAtSetupOne] =
    useState<any>(profileDetails);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleSelect = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = async () => {
    if (profileDetailsAtSetupOne.userName === "" || undefined) {
      setErrorMessage("Enter User Name ");
      return;
    }
    if (profileDetailsAtSetupOne.userName.length > 10) {
      setErrorMessage("UserName should be less than 10 characters");
      return;
    }
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;
    const valid = alphanumericRegex.test(profileDetailsAtSetupOne.userName);
    if (!valid) {
      setErrorMessage("UserName should contain only Alpha Numeric characters");
      return;
    }
    const firstLetter = profileDetailsAtSetupOne.userName.charAt(0);
    const isCapital = firstLetter === firstLetter.toUpperCase();
    if (!isCapital) {
      setErrorMessage("UserName should be start with capital letter");
      return;
    }
    const ifUserNameAlreadyAvailable: any = await api
      .post(`/userName`, { userName: profileDetailsAtSetupOne.userName })
      .catch((e) => { console.log("") });
    if (ifUserNameAlreadyAvailable?.status === 200) {
      let profile = { ...profileDetailsAtSetupOne };
      profile.userName = "";
      setProfileDetailsAtSetupOne({ ...profile });
      setErrorMessage("UserName Already exists!");
      return;
    }
    const profileWithAddress = { ...profileDetailsAtSetupOne };
    profileWithAddress.userWallet = address;
    profileWithAddress.language = profileDetailsAtSetupOne?.language;
    updateProfileDetails(profileWithAddress);
    handleProfileSetupOneModelClose();
  };

  const handleCloseToaster = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    if (errorMessage !== "") {
      setTimeout(() => setErrorMessage(""), 2000);
    }
  }, [errorMessage]);

  const handleSelectLanguage = (language: string) => {
    let profile = { ...profileDetailsAtSetupOne };
    profile.language = language;
    setProfileDetailsAtSetupOne({ ...profile });
    toggleSelect();
  };

  const CloseDropmenu = () => {
    isOpen && setIsOpen(false);
  };
  return (
    <>
      <div className="fixed z-[2]  inset-0 h-full w-full bg-[rgba(0,0,0,0.20)] backdrop-blur-[54px]  flex flex-row   justify-center xxl:items-center xl:items-center lg:items-center md:items-center sm:items-end xd:items-end">
        <div className="h-auto   p-[16px] flex flex-col rounded-[16px] w-[536px] border-[1px] border-[rgba(245,247,250,0.06)] bg-gradient-to-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom !backdrop-blur-[54.36563491821289px]">
          <div className="mb-[35px] xxl:flex xl:flex lg:flex md:flex sm:hidden xd:hidden flex-row justify-between items-center text-[white] text-[24px] font-inter font-[700]">
            <div>{t("profile_setup")}</div>
          </div>
          <div className="xxl:hidden xl:hidden lg:hidden md:hidden sm:flex xd:flex flex-col justify-center items-center ">
            <div className="text-[white] text-[24px] font-inter font-[700] mb-10">
              {t("profile_setup")}
            </div>
          </div>
          <div className="xxl:px-[24px] xl:px-[24px] lg:px-[24px]  md:px-[0px] sm:px-[0px] xd:px-[0px] flex flex-col font-[manrope] text-[#7D8595] text-[15px] font-[400] gap-[8px] w-full">
            <div>{t("username")} *</div>
            <input
              required
              type="text"
              maxLength={20}
              onChange={(e: any) => {
                let profile = { ...profileDetailsAtSetupOne };
                profile.userName = e.target.value;
                setProfileDetailsAtSetupOne({ ...profile });
              }}
              placeholder="Username"
              value={profileDetailsAtSetupOne?.userName}
              className="w-full text-[white] py-[14px] px-[16px] outline-none bg-[#1D1E20] border-[#7D8595] border-[1px] rounded-[16px]"
            />
            <div>{t("language")} *</div>
            <div
              className="w-full cursor-pointer flex justify-between p-[0%_3%] text-[white] py-[14px] px-[16px] outline-none bg-[#1D1E20] border-[#7D8595] border-[1px] rounded-[16px]"
              onClick={() => toggleSelect()}
            >
              <div>
                {profileDetailsAtSetupOne === null
                  ? "English"
                  : profileDetailsAtSetupOne.language === "" || undefined
                    ? "English"
                    : profileDetailsAtSetupOne?.language}
              </div>
              <img src={Vectordown} alt="Vectordown" />
            </div>
            {isOpen && (
              <ClickAwayListener onClickAway={CloseDropmenu}>
                <div
                  id="dropdown-options"
                  className="bg-[#1D1E20] border-[#7D8595] border-[1px] absolute custom-scrollbar !z-[100] rounded-[1rem] leading-9 text-white overflow-auto scroll-smooth backdrop-blur-sm  2xl:w-[28.154rem] xl:w-[28.175rem] lg:w-[28.175rem] md:w-[31.175rem] sm:w-[14.625rem] xd:w-[24.425rem] 2xl:h-[5rem] xl:h-[5rem] lg:h-[4rem] md:h-[3.5rem] sm:h-[3.425rem] max-sm:h-[3.425rem] xxl:left-[2.6rem] xl:left-[2.7rem] lg:left-[2.7rem] md:left-[1.1rem] sm:left-[1.1rem] xd:left-[1.1rem] top-[16.5rem]"
                >
                  <div className=" pl-4">
                    {languages.map((language: string, key: number) => {
                      return (
                        <div
                          key={key}
                          className=" cursor-pointer"
                          onClick={() => handleSelectLanguage(language)}
                        >
                          {language}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </ClickAwayListener>
            )}
          </div>
          <button
            onClick={() => handleSubmit()}
            type="submit"
            className="h-[51px] flex flex-row justify-center items-center cursor-pointer px-[16px] rounded-[16px] backdrop-blur-[54.36563491821289px] mt-[24px] font-inter text-[16px] font-[500] text-center box_transparent bg-gradient-to-r  from-[#0FF] to-[#00F9A9] bg-clip-text text-transparent shadow-box_shadow w-full"
          >
            <div className="w-full text-center">{t("next")}</div>
          </button>
        </div>
        {errorMessage !== "" && (
          <Toaster
            message={errorMessage}
            handleCloseToaster={handleCloseToaster}
          />
        )}
      </div>
    </>
  );
};

export default ProfileSetUpOne;
