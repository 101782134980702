import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { api, backendHost, missionEndpoints } from "../../helper/constants";
import { useStore } from "store";
import MissionItem from "./MissionItem";
import Discordpopup from "pages/Discordpopup";
import TwitterPopup from "pages/TwitterPopup";

const Missions = () => {
  const { t } = useTranslation();
  const { profileDetails } = useStore();
  const [completedMissions, setCompletedMissions] = useState({
    steam: true,
    twitter: true,
    discord: true,
  });
  const [discordClicked, setIsDiscordClicked] = useState<boolean>(false);
  const [twitterClicked, setTwitterClicked] = useState<boolean>(false);

  const fetchPendingMissions = useCallback(async () => {
    const missions: any = await api
      .get("/getNotCompletedMissions", {
        params: { userWallet: profileDetails.userWallet },
      })
      .catch((err: any) => {
        console.log("");
      });
    missions.data.userDetails.map((mission: any) => {
      setCompletedMissions((prevState) => ({
        ...prevState,
        [mission.platform]: false,
      }));
    });
  }, [profileDetails.userWallet]);

  useEffect(() => {
    fetchPendingMissions();
  }, [fetchPendingMissions]);

  const handleSteamConnect = async () => {
    const authUrl = new URL(missionEndpoints.steam, backendHost);
    authUrl.searchParams.append("useraddress", profileDetails.userWallet);
    authUrl.searchParams.append("mission_id", "m_fol_sm_092023");
    const steamPopup = window.open(authUrl, "steamPopup", "width=500");
    window.onmessage = function (ev) {
      if (ev.data.eventType === "auth status" && steamPopup) {
        setCompletedMissions((prevState) => ({
          ...prevState,
          steam: !!parseInt(ev.data.authStatus),
        }));
        steamPopup.close();
      }
    };
  };

  const handleDiscordClick = async () => {
    setIsDiscordClicked(true);
  };

  const handleTwitterClick = async (e: any) => {
    setTwitterClicked(true);
  };

  const handleCloseTwitterPopup = (value: boolean) => {
    if (value) {
      fetchPendingMissions();
    }
    setTwitterClicked(false);
  };

  const handleCloseDiscordPopup = (value: boolean) => {
    if (value) {
      fetchPendingMissions();
    }
    setIsDiscordClicked(false);
  };

  const handleTwitterMissionCompletion = () => {
    fetchPendingMissions();
  };

  return (
    <div className="xxl:w-1/2  xl:w-1/2 lg:w-1/2 md:w-auto sm:w-auto xd:w-auto h-[39.375rem] rounded-[1rem] border-[0.063rem] border-[#f5f7fa0f] pl-4">
      <div className=" text-[#9AA4B2] font-manrope font-[400] text-[0.875rem] pt-3">
        {t("ongoing_mission")}
      </div>
      {!completedMissions.discord && (
        <MissionItem
          onClick={() => handleDiscordClick()}
          translationKey="join_discord"
          points={20}
        />
      )}
      {!completedMissions.twitter && (
        <MissionItem
          onClick={handleTwitterClick}
          translationKey="Connect_twitter_1"
          points={20}
        />
      )}
      {!completedMissions.steam && (
        <MissionItem
          onClick={handleSteamConnect}
          translationKey="connect_steam"
          points={20}
        />
      )}
      {completedMissions.discord &&
        completedMissions.twitter &&
        completedMissions.steam && (
          <MissionItem translationKey="more_missions_way" />
        )}
      {discordClicked && (
        <Discordpopup handleCloseDiscordPopup={handleCloseDiscordPopup} />
      )}
      {twitterClicked && (
        <TwitterPopup
          handleCloseTwitterPopup={handleCloseTwitterPopup}
          userWallet={profileDetails.userWallet}
          onSuccess={handleTwitterMissionCompletion}
        />
      )}
    </div>
  );
};

export default Missions;
