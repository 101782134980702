import React, { useState, useEffect } from "react";
import GamingPad from "../../assets/icons/gaming-pad.svg";
import GamingIcon from "../../assets/images/gaming_icon.png";
import PenNib from "../../assets/icons/pen_nib.svg";
import EarlyAccess from "../../assets/icons/early_access.svg";
import Certificate from "../../assets/icons/certificate.svg";
import RedDiamond from "../../assets/icons/red_diamond.svg";
import WhyEllipse from "../../assets/images/WhyEllipse.svg";
import { useTranslation } from "react-i18next";

const SCROLL_SPEED2 = 2.3;
const WhyJoinKanaLabs = () => {
  const { t } = useTranslation();
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const ToggleScrollY = () => {
      setScrollY(window.scrollY / SCROLL_SPEED2);
    };
    window.addEventListener("scroll", ToggleScrollY);
    return () => {
      window.removeEventListener("scroll", ToggleScrollY);
    };
  }, []);

  return (
    <>
      {" "}
      <div className="flex items-center justify-center h-full w-full sticky ">
        <div className="  relative gap-[20px] xxl:py-[104px] xl:py-[104px] lg:py-[154px] md:pt-[104px] md:py-[71px] sm:pt-[194px] xd:pt-[194px] sm:pb-[91px] xd:pb-[91px] xxl:px-[302px]  xl:px-[162px] lg:px-[75px]   md:px-[20px]   sm:px-[20px]   xd:px-[20px] flex xxl:flex-row xl:flex-row lg:flex-row md:flex-col sm:flex-col xd:flex-col justify-center items-center ">
          <img
            src={WhyEllipse}
            alt="WhyEllipse"
            className=" absolute left-10"
          />
          <div className=" relative bg-waitlist_blur bg-no-repeat bg-right bg-contain xxl:!w-1/2 xl:!w-1/2 lg:!w-1/2 md:!w-full sm:!w-full xd:!w-full ">
            <div className=" h-[276px]  z-[1] xxl:py-[40px] xl:py-[40px] lg:py-[40px] md:py-[35px] sm:py-[35px] xd:py-[35px] xxl:px-[56px] xl:px-[56px] lg:px-[56px] md:px-[32px] sm:px-[32px] xd:px-[32px] rounded-[16px] border-[1px] border-[rgba(245,247,250,0.06)]  backdrop-blur-[54.36563491821289px] shadow-custom bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] ">
              <img
                src={GamingIcon}
                alt="gaming icon"
                className="xxl:flex xl:flex lg:flex md:flex  sm:hidden  xd:hidden"
              />
              <div className="flex flex-row justify-between items-end xxl:mt-[-20px] xl:mt-[-20px] lg:mt-[20px] md:mt-[0px] ">
                <div className="text-[white] text-[26px] font-[600] leading-none font-urbanist flex-[0.7]">
                  {t("why_join_kanalabs_gaming_ecosystem")}
                </div>
                <div>
                  <img
                    src={GamingPad}
                    alt="gaming pad"
                    className="flex-[0.2]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="vertical_scroll_container !z-[0] xxl:h-[520px] xl:h-[520px] lg:h-[520px] md:h-[520px] sm:h-[520px] xd:h-[520px] overflow-auto  flex flex-col overflow-y-auto  !mr-[-10px] xxl:!w-1/2 xl:!w-1/2 lg:!w-1/2 md:!w-full sm:!w-full xd:!w-full backdrop-blur-[54.36563491821289px] vertical_bg_container ">
            <div
              style={{ transform: `translatey(-${scrollY}px)` }}
              className=" xxl:mt-[0px] xl:mt-[50px] lg:mt-[100px] md:mt-[180px] sm:mt-[320px] xd:mt-[340px] my-[24px]  p-[24px] gap-[24px] xxl:!h-[152px] xl:!h-[152px] lg:!h-[152px] md:!h-[152px] sm:!h-[152px] xd:!h-[202px] flex flex-row justify-start items-start rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom "
            >
              <div className="flex flex-row justify-center xxl:items-end xl:items-end lg:items-end md:items-center sm:items-center xd:items-center rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-box_shadow xxl:!w-[104px] xxl:!h-[104px] xl:!w-[104px] xl:!h-[104px] lg:!w-[104px] lg:!h-[104px] md:!w-[104px] md:!h-[104px] sm:!w-[100px] sm:!h-[100px] xd:!w-[90px] xd:!h-[90px] flex-[0.25]">
                <img src={PenNib} alt="PenNib" />
              </div>
              <div className=" flex flex-col justify-start items-start text-[15px] font-manrope  flex-[0.75]">
                <div className="text-[white] font-[700]">
                  {t("experience_new")}
                </div>
                <div className="text-[#7D8595] font-[400]">
                  {t("dive_into")}
                </div>
              </div>
            </div>
            <div
              style={{ transform: `translatey(-${scrollY}px)` }}
              className=" mb-[24px] p-[24px] gap-[24px] xxl:!h-[152px] xl:!h-[152px] lg:!h-[152px] md:!h-[152px] sm:!h-[152px] xd:!h-[202px] flex flex-row justify-start items-start rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom "
            >
              <div className="flex flex-row justify-center xxl:items-end xl:items-end lg:items-end md:items-center sm:items-center xd:items-center rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-box_shadow xxl:!w-[104px] xxl:!h-[104px] xl:!w-[104px] xl:!h-[104px] lg:!w-[104px] lg:!h-[104px] md:!w-[104px] md:!h-[104px] sm:!w-[100px] sm:!h-[100px] xd:!w-[90px] xd:!h-[90px]  flex-[0.25]">
                <img src={EarlyAccess} alt="EarlyAccess" />
              </div>
              <div className=" flex flex-col justify-start items-start text-[15px] font-manrope  flex-[0.75]">
                <div className="text-[white] font-[700]">
                  {t("early_Access_privilages")}
                </div>
                <div className="text-[#7D8595] font-[400]">
                  {t("be_the_first_to_play")}
                </div>
              </div>
            </div>
            <div
              style={{ transform: `translatey(-${scrollY}px)` }}
              className=" mb-[24px] p-[24px] gap-[24px] xxl:!h-[152px] xl:!h-[152px] lg:!h-[152px] md:!h-[152px] sm:!h-[152px] xd:!h-[202px] flex flex-row justify-start items-start rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom "
            >
              <div className="flex flex-row justify-center xxl:items-end xl:items-end lg:items-end md:items-center sm:items-center  xd:items-center rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-box_shadow xxl:!w-[104px] xxl:!h-[104px] xl:!w-[104px] xl:!h-[104px] lg:!w-[104px] lg:!h-[104px] md:!w-[104px] md:!h-[104px] sm:!w-[100px] sm:!h-[100px] xd:!w-[90px] xd:!h-[90px]  flex-[0.25]">
                <img src={RedDiamond} alt="RedDiamond" />
              </div>
              <div className=" flex flex-col justify-start items-start text-[15px] font-manrope  flex-[0.75]">
                <div className="text-[white] font-[700]">
                  {t("exclusive_mission")}
                </div>
                <div className="text-[#7D8595] font-[400]">
                  {t("compare_missions")}
                </div>
              </div>
            </div>
            <div
              style={{ transform: `translatey(-${scrollY}px)` }}
              className=" mb-[24px] p-[24px] gap-[24px] xxl:!h-[152px] xl:!h-[152px] lg:!h-[152px] md:!h-[152px] sm:!h-[152px] xd:!h-[202px] flex flex-row justify-start items-start rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-to-br from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-custom  "
            >
              <div className="flex flex-row justify-center xxl:!items-end xl:!items-end lg:!items-end md:!items-center sm:!items-center xd:!items-center rounded-[16px]  border-[1px] border-[rgba(245,247,250,0.06)] backdrop-blur-[54.36563491821289px] bg-gradient-tr from-[rgba(245,247,250,0.06)] to-[rgba(245,247,250,0.00)] shadow-box_shadow xxl:!w-[104px] xxl:!h-[104px] xl:!w-[104px] xl:!h-[104px] lg:!w-[104px] lg:!h-[104px] md:!w-[104px] md:!h-[104px] sm:!w-[100px] sm:!h-[100px] xd:!w-[90px] xd:!h-[90px]  flex-[0.25] ">
                <img src={Certificate} alt="Certificate" />
              </div>
              <div className=" flex flex-col justify-start items-start text-[15px] font-manrope  flex-[0.75]">
                <div className="text-[white] font-[700]">
                  {t("flex_your_achievements")}
                </div>
                <div className="text-[#7D8595] font-[400]">
                  {t("unlock_exclusive_digital")}
                </div>
              </div>
            </div>
            <div className="absolute mask-gradient"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhyJoinKanaLabs;
