import axios from 'axios';
import { api } from './constants';
import { NetworkNames, initializeSdkGateway } from '@kanalabs/mirai';
import { Contract } from 'ethers';
import abi from '../abi/membershipABI.json'
import levelABI from '../abi/levelABI.json'

export const generateShortURL = async (url: any) => {
  try {
    const headers: any = {
      'apikey': process.env.REACT_APP_SHORTEND_API_KEY,
    };
    const response: any = await axios.post('https://api.apilayer.com/short_url/hash', url, {
      headers: headers,
    })
      .catch(e => console.log(""));
    return response.data

  } catch (error) {
    console.log("")
  }
}


export const reduceLengthLink = (data?: string) => {
  if (data) {
    const length = data.length;
    return data.substring(0, 5) + '....' + data.substring(length - 10, length - 0);
  }
  return;
};

export const handleGetUserDetails = async (address: string) => {
  try {
    const getUserDetailsResponse: any = await api.get(`/getUserDetails?userWallet=${address}`).catch((e) => { return { error: true, userDetails: null } });
    if (getUserDetailsResponse?.status === 200) {
      return { error: false, userDetails: getUserDetailsResponse?.data?.userDetails }
    }
    return getUserDetailsResponse
  } catch (e) {
    return { error: true, userDetails: [] }
  }
}

export const handleInitWallet = async (web3Instance: any) => {
  try {
    const privateKey = await web3Instance.provider.request({
      method: "private_key",
    });
    const sdk = await initializeSdkGateway(
      {
        privateKey: `0x${privateKey}`,
      },
      {
        networks: [NetworkNames.Polygon],
        projectKey: ''
      },
    );
    const mumbaiInstance = sdk.setCurrentInstance(NetworkNames.Polygon);
    const address = await mumbaiInstance.getCounterFactualAddress();
    return { status: false, sdk: sdk, address: address }
  } catch (e) {
    return { status: true, sdk: "", address: "" }
  }
};

export const getNumberOfReferrals = async (address: string) => {
  try {
    const response: any = await api.get(`/getReferralRecords?referrerWallet=${address}`).catch(e => console.log(""))
    if (response?.status === 200) {
      return { error: false, numberOfReferral: response.data.userDetails.length }
    }
    return { error: true, numberOfReferral: 0 }
  } catch (e) {
    return { error: true, numberOfReferral: 0 }
  }
}

export const handleWhitelist = async (address: string, level: any) => {
  try {
    const response: any = await api.post(`/whitelisted`, { address: `${address}`, level: level, isWhitelistedLevel: true })
    if (response?.status === 200) {
      return { error: false, isWhitelisted: true }
    }
    return { error: true, isWhitelisted: false }
  } catch (e) {
    return { error: true, isWhitelisted: false }
  }
}

export const handleMintMemberShipNFT = async (sdk: any) => {
  try {

    const contract = new Contract(process.env.REACT_APP_MEMBERSHIP_CONTRACT as string, abi)
    const transactionData: any = contract.interface.encodeFunctionData('claimMembership')
    const mumbaiInstance = sdk.setCurrentInstance(NetworkNames.Polygon)
    const sender = await mumbaiInstance.getCounterFactualAddress()
    const clear = await mumbaiInstance.clearUserOpsFromBatch()
    const ops = await mumbaiInstance.addUserOpsToBatch({
      to: process.env.REACT_APP_MEMBERSHIP_CONTRACT,
      data: transactionData,
    })
    const op = await mumbaiInstance.estimate({
      url: 'https://arka.etherspot.io/',
      api_key: process.env.REACT_APP_PAYMASTER_API_KEY,
      context: { mode: 'sponsor' },
    })
    const uoHash = await mumbaiInstance.send(op)
    let userOpsReceipt = null
    const timeout = Date.now() + 70000 // 1 minute timeout
    while (userOpsReceipt == null && Date.now() < timeout) {

      userOpsReceipt = await mumbaiInstance.getUserOpReceipt(uoHash)
    }
    return { error: false, hash: uoHash }
  } catch (e) {
    return { error: true, hash: '' }
  }
}

export const preventPasteNegativeNumber = (e: any) => {
  const clipboardData = e.clipboardData || (window as any).clipboardData;
  const value = clipboardData.getData("text");
  if (!value || value.includes("-")) {
    e.preventDefault();
  } else {
    const pastedData = parseFloat(value);
    if (pastedData < 0) {
      e.preventDefault();
    }
  }
};


export const handleGetBadgeDetails = async (userWallet: any, sdk: any) => {
  try {
    const mumbaiInstance = sdk.setCurrentInstance(NetworkNames.Polygon);
    const nfts = await mumbaiInstance.getNftList({ chainId: 137, account: userWallet })
    return { error: false, badges: nfts }
  } catch (e) {
    return { error: true, badges: null }
  }
}

export const handleGetLevelBasedBadges = async (address: any, sdk: any, level: any) => {
  try {
    const contract = new Contract(process.env.REACT_APP_LEVEL_CONTRACT as string, levelABI)
    const transactionData: any = contract.interface.encodeFunctionData('mint', [level])
    const mumbaiInstance = sdk.setCurrentInstance(NetworkNames.Polygon)
    const sender = await mumbaiInstance.getCounterFactualAddress()
    const clear = await mumbaiInstance.clearUserOpsFromBatch()
    const ops = await mumbaiInstance.addUserOpsToBatch({
      to: process.env.REACT_APP_LEVEL_CONTRACT,
      data: transactionData,
    })
    const op = await mumbaiInstance.estimate({
      url: 'https://arka.etherspot.io/',
      api_key: process.env.REACT_APP_PAYMASTER_API_KEY,
      context: { mode: 'sponsor' },
    })
    const uoHash = await mumbaiInstance.send(op)
    let userOpsReceipt = null
    const timeout = Date.now() + 70000 // 1 minute timeout
    while (userOpsReceipt == null && Date.now() < timeout) {
      // await sleep(10)
      userOpsReceipt = await mumbaiInstance.getUserOpReceipt(uoHash)
    }
    return { error: false, hash: uoHash }
  } catch (e) {
    return { error: true, hash: '' }
  }

}

export const handleUpdateClaimInDB = async (address: any, level: any) => {
  try {
    const response: any = await api.post(`/claimedBadge`, { userWallet: `${address}`, badgeId: level })

    if (response?.status === 200) {
      return { error: false, status: response }
    }
    
    return { error: true, status: "" }
  } catch (e) {
    

    return { error: true, status: '' }
  }
}

export const handleGetClaimFromDB = async (address: any) => {
  try {
    const response: any = await api.get(`/getClaimedBadge?userWallet=${address}`)
  
    if (response?.status === 200) {
      return { error: false, status: response?.data?.userDetails }
    }
    return { error: true, status: "" }
  } catch (e) {
    return { error: true, status: '' }
  }
}
